import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getOrganizationalMeasuresData, genPgcPDF, getPgcFile } from "../Utils";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPGC from "../../NumDossPGC";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import { paragraphs } from "./Const"
import "../../../Styles/PGC/Page3.scss";

const PgcPage3 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.pgc.index);
  const pgcValue = useSelector((state) => state.pgc.pgcValue);

  const [truckAccess, setTruckAccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [rj0date, setRj0date] = useState('')
  const [truckAccessDescription, setTruckAccessDescription] = useState({
    blueText: '',
    redText: ''
  })
  const [meetingRoomDescription, setMeetingRoomDescription] = useState('')
  const [asbestosLead, setAsbestosLead] = useState({
    asbestos: false,
    lead: false
  })

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!dossierValue) return
    loadData()

  }, [pgcValue]);

  const loadData = async () => {
    if (!pgcValue) return resetData();
    setIsLoading(true);
    const responseOrganizationalMeasuresData = await getOrganizationalMeasuresData(pgcValue);

    if (responseOrganizationalMeasuresData) {
      const date = new Date(responseOrganizationalMeasuresData.rj_date).toLocaleDateString('en-CA');
      const truckAccessDescriptionLoaded = responseOrganizationalMeasuresData.truck_access_description === "" ? { blueText: '', redText: '' } : JSON.parse(responseOrganizationalMeasuresData.truck_access_description);
      const asbestosLeadLoaded = responseOrganizationalMeasuresData.asbestos_lead === "" ? { asbestos: false, lead: false } : JSON.parse(responseOrganizationalMeasuresData.asbestos_lead);

      unstable_batchedUpdates(() => {
        setRj0date(date);
        setTruckAccessDescription(truckAccessDescriptionLoaded);
        setMeetingRoomDescription(responseOrganizationalMeasuresData.meeting_room_description);
        setAsbestosLead(asbestosLeadLoaded);
        setIsLoading(false);
      });

      if (responseOrganizationalMeasuresData.images && responseOrganizationalMeasuresData.images.length > 0) {
        const image = responseOrganizationalMeasuresData.images[0];
        if (image && image.img && image.img.buffer && image.img.buffer.data) {
          const buffer = new Uint8Array(image.img.buffer.data).buffer;
          const blob = new Blob([buffer], { type: 'image/png' });
          const file = new File([blob], image.name, { type: 'image/png' });

          setTruckAccess(file);
        }
      } else setTruckAccess(null);
    } else {
      unstable_batchedUpdates(() => {
        resetData();
        setIsLoading(false);
      })
    }
  };

  const handleSaveData = async (e) => {
    e.preventDefault();
    setDisableButton(true);

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', index);
    formData.append('pgcValue', pgcValue);
    formData.append('rj0date', rj0date);
    formData.append('truckAccessDescription', JSON.stringify(truckAccessDescription));
    formData.append('meetingRoomDescription', meetingRoomDescription);
    formData.append('asbestosLead', JSON.stringify(asbestosLead));

    if (truckAccess) formData.append('truckAccess', truckAccess);

    try {
      const response = await fetch('/api/pgc/updateOrganizationalMeasuresData', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDisableButton(false);
    }
  }

  const resetData = () => {
    setRj0date('');
    setTruckAccessDescription({
      blueText: '',
      redText: ''
    })
    setMeetingRoomDescription('');
    setAsbestosLead({
      asbestos: false,
      lead: false
    })
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPgcPDF(pgcValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`PGC`));
  }, [pgcValue]);

  const prevPage = () => {
    history.push("/pgc/page2");
  }

  const nextPage = () => {
    history.push("/pgc/page4");
  }

  return (
    <div className="pgc-page3">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Plan Général de Coordination (Mesures d'organisation générale)</h1>
      <NumDossPGC />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-title">
              <th className="custom-cell-label" colSpan={4}><strong>Communication des documents</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td>Date 1<sup>er</sup> RJ</td>
              <td colSpan={3}><input type="date" value={rj0date} onChange={(e) => setRj0date(e.target.value)} /></td>
            </tr>
            <tr className="custom-cell-title">
              <th className="custom-cell-label" colSpan={4}><strong>Accès camion (description)</strong></th>
            </tr>
            <tr className="custom-cell-content">
              <td colSpan={2} style={{ width: '50%' }}><textarea style={{ color: "blue" }} value={truckAccessDescription.blueText} onChange={(e) => setTruckAccessDescription({ ...truckAccessDescription, blueText: e.target.value })} placeholder="Texte en bleu"></textarea></td>
              <td colSpan={2} style={{ width: '50%' }}><textarea style={{ color: "red" }} value={truckAccessDescription.redText} onChange={(e) => setTruckAccessDescription({ ...truckAccessDescription, redText: e.target.value })} placeholder="Texte en rouge"></textarea></td>
            </tr>
            <tr className="custom-cell">
              <td>Image</td>
              <td colSpan={3}>
                <input
                  type="file"
                  name="truck-access"
                  key={`img-ta`}
                  multiple
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setTruckAccess(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <th className="custom-cell-label" colSpan={4}><strong>Salle de réunion</strong></th>
            </tr>
            <tr className="custom-cell">
              <td><span>Description</span></td>
              <td colSpan={3}>
                <select value={paragraphs.indexOf(meetingRoomDescription)} onChange={(e) => setMeetingRoomDescription(paragraphs[e.target.value])}>
                  <option value={0}></option>
                  <option value={1}>Début du chantier</option>
                  <option value={2}>Fin du chantier</option>
                </select>
              </td>
            </tr>
            <tr className="custom-cell" style={{ textAlign: "center" }}>
              <td colSpan={4}><textarea value={meetingRoomDescription} onChange={(e) => setMeetingRoomDescription(e.target.value)}></textarea></td>
            </tr>
            <tr className="custom-cell-title">
              <th className="custom-cell-label" colSpan={4}><strong>Amiante / Plomb</strong></th>
            </tr>
            <tr className="custom-cell">
              <td colSpan={2} style={{ textAlign: "center" }}><span style={{ marginRight: "5px" }}>Amiante</span><input type="checkbox" checked={asbestosLead.asbestos} onChange={(e) => setAsbestosLead({ ...asbestosLead, asbestos: e.target.checked })} /></td>
              <td colSpan={2} style={{ textAlign: "center" }}><span style={{ marginRight: "5px" }}>Plomb</span><input type="checkbox" checked={asbestosLead.lead} onChange={(e) => setAsbestosLead({ ...asbestosLead, lead: e.target.checked })} /></td>
            </tr>
          </tbody>
        </table>
        {truckAccess &&
          <div className="img-item">
            <IconButton
              className="item-delete"
              style={{
                color: "white",
                position: "absolute",
                top: 0,
                right: 0
              }}
              onClick={() => setTruckAccess(null)}
            >
              <HighlightOffIcon />
            </IconButton>
            <img
              src={URL.createObjectURL(truckAccess)}
              alt="truck-access"
              style={{ height: "200px" }}
            />
            <p>Accès camion</p>
          </div>}
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !pgcValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !pgcValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !pgcValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !pgcValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !pgcValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default PgcPage3;