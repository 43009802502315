import React from 'react';
import Sheet3Table from './SheetTables/Sheet3Table';
import Sheet5Table from './SheetTables/Sheet5Table';
import Sheet8Table from './SheetTables/Sheet8Table';
import Sheet11Table from './SheetTables/Sheet11Table';
import SheetOther from './SheetTables/SheetOther';
import '../../../Styles/DIUO/TableComponent.scss';

const TableComponent = ({ index, sheet, sheetNumber, onInputChange }) => {
  const handleChange = (field) => (e) => {
    onInputChange(field, e.target.value);
  };

  const handleFileChange = (field) => (e) => {
    if (e.target.files && e.target.files[0]) {
      onInputChange(field, e.target.files[0]);
    } else {
      onInputChange(field, null);
    }
  };

  const handleDeleteImage = (field) => {
    onInputChange(field, null);
  };

  switch (sheetNumber) {
    case 3:
      return <Sheet3Table index={index} sheet={sheet} />

    case 5:
      return <Sheet5Table index={index} sheet={sheet} />

    case 8:
      return <Sheet8Table index={index} sheet={sheet} />

    case 11:
      return <Sheet11Table index={index} sheet={sheet} />

    default:
      return <SheetOther index={index} sheet={sheet} sheetNumber={sheetNumber} handleChange={handleChange} handleFileChange={handleFileChange} handleDeleteImage={handleDeleteImage} />
  }
};

export default TableComponent;
