import React from 'react';

const Sheet11Table = ({ index, sheet }) => {
  return (
    <div className="sheet-11-table">
      <div className='sheet-11-table-1'>
        <table>
          <thead>
            <tr>
              <th colSpan="2" ><strong>Fiche n°{index + 1} {sheet.title}</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2" style={{ padding: '15px 0', backgroundColor: '#9b9b9b', fontWeight: 'bold' }}>Description</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='sheet-11-table-details'>
        Cette obligation est incluse dans les polices collectives d'assurance contre l'incendie du Maître d'ouvrage, pour prévenir les risques d'incendie liés à l'utilisation de matériels dit «à point chaud».<br /><br />
        <u>Le permis de feu est un document administratif de prévention:</u><br />
        <span>-	Rédigé par l'entreprise exécutante,</span><br />
        <span>-	Complété par le chef d'équipe,</span><br />
        <span>-	Complété et visé par le conducteur de travaux du maître d'ouvrage</span><br /><br />
        <strong>Désignation des travaux employés:</strong><br />
        <span>-	La soudure électrique</span><br />
        <span>-	La soudure au chalumeau</span><br />
        <span>-	Le découpage électrique</span><br />
        <span>-	Le découpage au chalumeau</span><br />
        <span>-	La lampe à souder</span><br />
        <span>-	Le dégagement d'étincelles</span><br /><br />
        <strong>Rappel des principales consignes générales:</strong><br />
        <span>-	Délimiter la zone de danger</span><br />
        <span>-	Enlever les produits inflammables qui s'y trouvent</span><br />
        <span>-	Éliminer les risques particuliers d'incendie</span><br />
        <span>-	Prévoir les moyens d'interventions appropriés (type et capacité des extincteurs)</span><br />
        <span>-	Présence auprès de l'opérateur, d'au moins deux heures après la cessation du travail proprement dit</span><br />
        <span>-	Assurer la présence d'un personnel spécial d'intervention pour tous travaux effectués de nuit, les dimanches et jours fériés, dans des endroits isolés.</span><br /><br />
        Ci-joint, un exemplaire de permis de feu, émis par le CNPP de VERNON.<br />
      </div>
    </div>
  );
};

export default Sheet11Table;