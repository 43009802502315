import React from 'react';
import extincteurCo2ClasseB from './img/extincteurCo2ClasseB.jpg';
import extincteurEauAdditif from './img/extincteurEauAdditif.jpg';
import extincteurPoudreAbc from './img/extincteurPoudreAbc.jpg';

const Sheet8Table = ({ index, sheet }) => {
  return (
    <div className="sheet-8-table">
      <div className='sheet-8-table-1'>
        <table>
          <thead>
            <tr>
              <th colSpan="2" ><strong>Fiche n°{index + 1} {sheet.title}</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2" style={{ padding: '15px 0', backgroundColor: '#9b9b9b', fontWeight: 'bold' }}>Description</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='img-container'>
        <img src={extincteurCo2ClasseB} alt="extincteurCo2ClasseB"></img>
        <img src={extincteurEauAdditif} alt="extincteurEauAdditif"></img>
        <img src={extincteurPoudreAbc} alt="extincteurPoudreAbc"></img>
      </div>
      <div className='sheet-8-table-details'>
        <strong>3 TYPES DANS L’IMMEUBLE:</strong><br />
        <span>• <strong>CO2</strong> - Parkings - Locaux à risque</span><br />
        <span>• <strong>Poudre</strong> - TGBT et locaux informatiques</span><br />
        <span>• <strong>Eau + additif</strong></span><br /><br />
        <strong>SUR PLACE (1 fois/trimestre)</strong><br />
        <span>• respect de l’emplacement assigné</span><br />
        <span>• accessibilité correcte</span><br />
        <span>• bon état apparent</span><br /><br />
        <strong>EN ATELIER (1 fois/an) avec contrat de maintenance:</strong><br />
        <span>• nettoyage général</span><br />
        <span>• tests des mécanismes</span><br />
        <span>• démontage et contrôle du sparklet, ou vérification de la pression interne</span><br />
        <span>• contrôle de la charge et suivant le cas :</span><br />
        <span><span>- vidange (eau)</span></span><br />
        <span><span>- détassage (poudre)</span></span><br />
        <span><span>- remplacement si nécessaire</span></span><br />
        <span>• présence du plombage</span><br />
        <span>• renseignement de l’étiquette de vérification</span><br /><br />
        <strong>RÉVISION (appareils âgés de 10 à 15 ans):</strong><br />
        <span>• examen détaillé de l’extérieur et de l’intérieur de chaque appareil</span><br />
        <span>• essai fonctionnel si nécessaire</span><br />
        <span>• décision de maintien en service ou mise au rebut.</span><br /><br />
        <span>Annexe (NORME NF S 61-919)</span><br />
      </div>
    </div>
  );
};

export default Sheet8Table;