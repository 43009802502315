export const FETCH_DIUOBYDOSS_BEGIN = 'FETCH_DIUOBYDOSS_BEGIN';
export const FETCH_DIUOBYDOSS_SUCCESS = 'FETCH_DIUOBYDOSS_SUCCESS';
export const FETCH_DIUOBYDOSS_FAILURE = 'FETCH_DIUOBYDOSS_FAILURE';
export const FETCH_DIUOBYDOSSVERIF_BEGIN = 'FETCH_DIUOBYDOSSVERIF_BEGIN';
export const FETCH_DIUOBYDOSSVERIF_SUCCESS = 'FETCH_DIUOBYDOSSVERIF_SUCCESS';
export const FETCH_DIUOBYDOSSVERIF_FAILURE = 'FETCH_DIUOBYDOSSVERIF_FAILURE';
export const CHANGE_INDEX_VALUE = 'CHANGE_INDEX_VALUE';

export function fetchDiuoByDoss(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({
      ndossier: ndossier
    })
  };
  return dispatch => {
    dispatch(fetchDiuoBegin());
    return fetch("/api/diuo/getDiuoByDoss", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchDiuoSuccess(json));
      })
      .catch(error => dispatch(fetchDiuoFailure(error)));
  };
}

export function fetchDiuoByDossVerif(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({
      ndossier: ndossier
    })
  };
  return dispatch => {
    dispatch(fetchDiuoVerifBegin());
    return fetch("/api/diuo/getDiuoByDoss", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchDiuoVerifSuccess(json));
      })
      .catch(error => dispatch(fetchDiuoVerifFailure(error)));
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function newDiuoIndexValue(newValue) {
  return dispatch => dispatch(changeDiuoIndexValue(newValue))
}

export const fetchDiuoBegin = () => ({
  type: FETCH_DIUOBYDOSS_BEGIN
});

export const fetchDiuoSuccess = data => ({
  type: FETCH_DIUOBYDOSS_SUCCESS,
  payload: { diuoByDoss: data }
});

export const fetchDiuoFailure = error => ({
  type: FETCH_DIUOBYDOSS_FAILURE,
  payload: { error }
});

export const fetchDiuoVerifBegin = () => ({
  type: FETCH_DIUOBYDOSSVERIF_BEGIN
});

export const fetchDiuoVerifSuccess = data => ({
  type: FETCH_DIUOBYDOSSVERIF_SUCCESS,
  payload: { diuoByDossVerif: data }
});

export const fetchDiuoVerifFailure = error => ({
  type: FETCH_DIUOBYDOSSVERIF_FAILURE,
  payload: { error }
});

export const changeDiuoIndexValue = index => ({
  type: CHANGE_INDEX_VALUE,
  payload: { index }
});