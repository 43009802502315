export const validators = {
  isValidNumber: (value) => {
    return !isNaN(Number(value)) && value.trim() !== '';
  },

  isValidSurface: (value) => {
    const number = Number(value);
    return !isNaN(number) && number >= 0;
  },

  isValidText: (value) => {
    return value.trim().length > 0;
  }
};

export const dataUtils = {
  initializeElementData: (config) => {
    const quantities = config.quantities
      ? Array(config.quantities.rows)
          .fill(null)
          .map((_, rowIndex) => ({
            rowIndex,
            values: config.quantities.headers.map((_, colIndex) => ({
              columnIndex: colIndex,
              value: "",
            })),
          }))
      : [];

    const informations = config.informations
      ? config.informations.rows.map((text, rowIndex) => ({
          rowIndex,
          text,
          value: "",
        }))
      : [];

    return { config, quantities, informations };
  },

  updateElementValue: (elementData, tableType, rowIndex, columnIndex, value) => {
    const newData = { ...elementData };

    if (tableType === "quantities") {
      newData.quantities[rowIndex].values[columnIndex].value = value;
    } else if (tableType === "informations") {
      if (columnIndex === 0) {
        newData.informations[rowIndex].text = value;
      } else {
        newData.informations[rowIndex].value = value;
      }
    }

    return newData;
  },
};