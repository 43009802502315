import React from "react";
import Autosuggest from "react-autosuggest";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
const getSuggestionValue = (suggestion) =>
  suggestion.prenom + " " + suggestion.nom;

const renderSuggestion = (suggestion) => (
  <span style={{ backgroundColor: "white", padding: "auto" }}>
    {suggestion.prenom + " " + suggestion.nom}
  </span>
);

class CompMo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showComp: [true, true, true, true],
      suggestions: [],
    };
    this.checkChange = this.checkChange.bind(this);
  }
  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.contact.filter((item) => {
        if (
          item.nom.toLowerCase().slice(0, inputLength) === inputValue &&
          item.fonction === "Maitre d'ouvrage"
        )
          return true;
        if (
          item.prenom.toLowerCase().slice(0, inputLength) === inputValue &&
          item.fonction === "Maitre d'ouvrage"
        )
          return true;
      });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (suggestion.nom !== "")
      this.props.setMo(
        suggestion.prenom + " " + suggestion.nom,
        suggestion.idcontact
      );
    else this.props.setMo(suggestion.prenom, suggestion.idcontact);
    this.props.handleChangeMoInfos(
      false,
      suggestion.adress,
      suggestion.city,
      suggestion.cp,
      suggestion.tel_port,
      suggestion.mail,
      suggestion.sexe
    );
  };

  /**
   * Affiche le type d'entreprise voulu et fais disparaître le reste
   * @param {number} index
   * @returns
   */
  displayComp(index) {
    if (this.state.showComp[index] == true) {
      return { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '-20px', width: '100%' };
    }
    return { display: "none" };
  }
  /**
   * Affiche le champ ou l'utlisateur peut rentrer le nom de l'entreprise
   * @param {number} index
   * @returns
   */
  showInput(index) {
    for (let i = 0; i < this.state.showComp.length; i++) {
      if (!this.state.showComp[i]) {
        if (this.state.showComp[index]) {
          return (
            <>:
              <input
                defaultValue={this.props.company}
                name="company"
                onChange={(event) => this.props.handleChange(event)}
                style={{ width: '100%' }}
              />
            </>
          );
        }
      }
    }
    return "";
  }

  /**
   * Permet de cocher le type d'entreprise responsable du chantier
   * @param {{}} event
   * @param {number} index
   */
  checkChange(event, index) {
    if (event.target.checked) {
      for (let i = 0; i < this.state.showComp.length; i++) {
        if (i != index) {
          this.state.showComp[i] = false;
        }
      }
      if (index == 0) {
        this.props.setCompany("SCI");
      }
      if (index == 1) {
        this.props.setCompany("SCCV");
      }
    } else {
      for (let i = 0; i < this.state.showComp.length; i++) {
        this.state.showComp[i] = true;
      }
      this.props.setCompany("");
    }
    this.setState(this.state.showComp);
  }

  render() {
    return (
      <React.Fragment>
        <div style={this.displayComp(0)}>
          <Checkbox onChange={(event) => this.checkChange(event, 0)} />
          <label>SCI</label>
          {this.showInput(0)}
        </div>
        <div style={this.displayComp(1)}>
          <Checkbox onChange={(event) => this.checkChange(event, 1)} />
          <label>SCCV</label>
          {this.showInput(1)}
        </div>
        <div style={this.displayComp(2)}>
          <Checkbox onChange={(event) => this.checkChange(event, 2)} />
          <label>Entreprise MO</label>
          {this.showInput(2)}
        </div>
        <div style={this.displayComp(3)}>
          <Checkbox onChange={(event) => this.checkChange(event, 3)} />
          <label>Ne rien afficher</label>
        </div>
        <div className="mo" style={{ marginTop: '-7px' }}>
          <label>Maître d'ouvrage : </label>
          <Autosuggest
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={this.onSuggestionSelected}
            inputProps={this.props.inputProps}
          />
        </div>
        <div>
          <label>Genre : </label>
          <select
            name="sexe"
            value={this.props.sexe}
            onChange={(event) => this.props.handleChangeMoInfos(event)}
          >
            <option value="homme">Homme</option>
            <option value="femme">Femme</option>
          </select>
        </div>
        <div>
          <label>Tel : </label>
          <input
            name="tel_port"
            value={this.props.tel_port}
            onChange={(event) => this.props.handleChangeMoInfos(event)}
          />
        </div>
        <div>
          <label>E-mail : </label>
          <input
            name="mail"
            value={this.props.mail}
            onChange={(event) => this.props.handleChangeMoInfos(event)}
          />
        </div>
        <div>
          <label>Adresse : </label>
          <input
            name="adress"
            value={this.props.adress}
            onChange={(event) => this.props.handleChangeMoInfos(event)}
          />
        </div>
        <div>
          <label>Ville : </label>
          <input
            name="city"
            value={this.props.city}
            onChange={(event) => this.props.handleChangeMoInfos(event)}
          />
        </div>
        <div>
          <label>Code Postal : </label>
          <input
            name="cp"
            value={this.props.cp}
            onChange={(event) => this.props.handleChangeMoInfos(event)}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  contact: state.contact.items,
});
export default connect(mapStateToProps)(CompMo);
