import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getReportData, genDiuoPDF, getDiuoFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import "../../../Styles/DIUO/Page8.scss";

const initialState = {
  documentsSubmitted: {
    yes: false,
    no: true
  },
  documentsNotAttached: {
    yes: false,
    no: true
  },
  comments: '',
  conclusion: "D’autres solutions techniques intégrant des dispositifs de sécurité peuvent être envisagées en amont de l’exécution des travaux pour assurer l’entretien ultérieur des ouvrages.\nLes équipements retenus par le Maître d’ouvrage et le Maître d’œuvre mettront en évidence toutes les règles de sécurité appropriées pour la protection du personnel de maintenance lors des interventions permanentes ou périodiques."
};

const DiuoPage8 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [state, setState] = useState(initialState);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    try {
      if (!index || !diuoValue) {
        setState(initialState);
        return
      }
      const response = await getReportData(diuoValue);
      const parsedResponse = response ? JSON.parse(response) : initialState;
      setState(parsedResponse);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!dossierValue) return
    loadData()
  }, [diuoValue]);

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        diuoValue,
        report: JSON.stringify(state)
      }),
    };

    try {
      const response = await fetch('/api/diuo/saveReport', requestOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to save technical lots');
      }
    } catch (err) {
      console.error('Error saving technical lots:', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genDiuoPDF(diuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page7");
  }

  const nextPage = () => {
    history.push("/diuo/page9");
  }

  return (
    <div className="diuo-page8">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Compte rendu de la visite de fin de travaux)</h1>
      <NumDossDIUO />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr>
              <th className="custom-cell-head-title" colSpan={2}>
                <strong>COMPTE RENDU DE LA VISITE DE FIN DE TRAVAUX</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}>PREPARATION DU D.I.U.O</td>
            </tr>
            <tr className="custom-cell">
              <td className="custom-cell-label">Les plans, fiches d’accès et éléments nécessaires à la Rédaction des fiches d’interventions (documents joints au D.I.U.O.) ont été remis au Coordonnateur SPS</td>
              <td className="custom-cell-value">
                <div>
                  Oui <input type="checkbox" name="yes" checked={state.documentsSubmitted.yes} onChange={(event) => setState({ ...state, documentsSubmitted: { ...state.documentsSubmitted, yes: event.target.checked, no: !event.target.checked } })} />
                </div>
                <div>
                  Non <input type="checkbox" name="no" checked={state.documentsSubmitted.no} onChange={(event) => setState({ ...state, documentsSubmitted: { ...state.documentsSubmitted, no: event.target.checked, yes: !event.target.checked } })} />
                </div>
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}>LISTE DES DOCUMENTS NON JOINTS AU D.I.U.O</td>
            </tr>
            <tr className="custom-cell">
              <td className="custom-cell-label">Le sommaire du DOE a été remis au Coordonnateur SPS</td>
              <td className="custom-cell-value">
                <div>
                  Oui <input type="checkbox" name="yes" checked={state.documentsNotAttached.yes} onChange={(event) => setState({ ...state, documentsNotAttached: { ...state.documentsNotAttached, yes: event.target.checked, no: !event.target.checked } })} />
                </div>
                <div>
                  Non <input type="checkbox" name="no" checked={state.documentsNotAttached.no} onChange={(event) => setState({ ...state, documentsNotAttached: { ...state.documentsNotAttached, no: event.target.checked, yes: !event.target.checked } })} />
                </div>
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}>COMMENTAIRES</td>
            </tr>
            <tr className="custom-cell">
              <td className="custom-cell-text" colSpan={2}>
                <textarea name="comments" value={state.comments} onChange={(event) => setState({ ...state, comments: event.target.value })} />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}>CONCLUSION</td>
            </tr>
            <tr className="custom-cell">
              <td className="custom-cell-text" colSpan={2}>
                <textarea name="conclusion" value={state.conclusion} onChange={(event) => setState({ ...state, conclusion: event.target.value })} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );

};

export default DiuoPage8;