import React from 'react';
import '../Styles/DocMenu.scss'
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MailIcon from '@material-ui/icons/Mail';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import history from './Routes/history';
import ConfirmRelance from './Factures/ConfirmRelance';
import PrintIcon from '@material-ui/icons/Print';
import printJS from 'print-js';
import { changeUrlFile, changeTypeFileName } from '../redux/actions/emailAction';
import { changeMoDetails } from '../redux/actions/contactActions';
import { fetchDossierInfos } from '../redux/actions/dossierActions';
import { connect } from "react-redux";
import { getContact } from './Utils';
class DocMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            openDialog: false,
            urlPdf: ""
        }
        this.othersWrapper = React.createRef();
        this.mailWrapper = React.createRef();
        this.downloadWrapper = React.createRef();
        this.changeOthersState = this.changeOthersState.bind(this);
        this.changeMailState = this.changeMailState.bind(this);
        this.changeDlState = this.changeDlState.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.clickToPrint = this.clickToPrint.bind(this);
    }
    componentDidMount() {
        const { dossier, dossierValue, contact } = this.props
        if (dossier, dossierValue, contact) {
            this.props.dispatch(changeMoDetails(getContact(dossier, contact, dossierValue)))
            this.props.dispatch(fetchDossierInfos(dossierValue))
        }
    }
    /**
     * Mise à jour des données du document concerné
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        const { dossier, dossierValue, contact, file } = this.props
        if (file !== prevProps.file || file === null) {
            const urlFile = file !== null ? URL.createObjectURL(file) : null;
            this.props.dispatch(changeUrlFile(urlFile))
        }
        if (dossierValue !== prevProps.dossierValue) {
            this.props.dispatch(changeMoDetails(getContact(dossier, contact, dossierValue)))
            this.props.dispatch(fetchDossierInfos(dossierValue))
        }
    }

    /**
     * Vérifie l'existence du document
     */
    async checkPdfFileAvailability(func) {
        if (this.props.file == null) {
            await this.props.getPDF();
        }
        await func();
    }

    /**
     * Ouvre l'affichage de l'impression du document précédemment généré
     */
    clickToPrint() {
        const url = URL.createObjectURL(this.props.file);
        printJS(url);
    }
    // clickToPrint() {
    //     const url = URL.createObjectURL(this.props.file);
    //     window.open(url, '_blank');
    // }

    /**
     * Télécharge le document précédemment généré
     */
    async downloadFile() {
        await this.props.getPDF();
        let url = await URL.createObjectURL(this.props.file);
        await this.setState({ urlPdf: url })
        let a = await document.createElement('a');
        a.href = url;
        a.download = `${this.props.fileName}${this.props.fileName !== 'calCSPS' ? '' : '_'+this.props.dossierValue}.pdf`;
        a.click();
    }

    async downloadExcelFile() {
        await this.props.getSourceFile()
        const url = await URL.createObjectURL(this.props.file);
        const a = document.createElement('a');
        a.href = url;
        await a.setAttribute('download', `${this.props.fileName}${this.props.fileName !== 'calCSPS' ? '' : ('_'+this.props.dossierValue)}.xlsx`);
        await document.body.appendChild(a);
        await a.click();
        await a.parentNode.removeChild(a);
    }

    async downloadWordFile() {
        await this.props.getSourceFile()
        const url = await URL.createObjectURL(this.props.file);
        const a = document.createElement('a');
        a.href = url;
        await a.setAttribute('download', `${this.props.fileName}.docx`);
        await document.body.appendChild(a);
        await a.click();
        await a.parentNode.removeChild(a);
    }
    /**
     * Envoie le document généré par mail
     */
    handleSubmit() {
        let mail = {}
        mail.sender = "ericguillet@coordogec.fr"
        mail.to = "ericguillet@coordogec.fr"
        mail.subject = "Relance"
        mail.text = "Bonjour, ceci est une relance dût a un retard ou seulement pour vous rappellez de nous fournir les documents dont nous avons besoin"
        mail.type_notif = "relance"
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(mail)
        };
        fetch("/api/sendMail", requestOptions)
    }
    setOpen(open) {
        this.setState({ openDialog: open })
    }
    handleOpenDialog() {
        this.setOpen(true)
    }
    changeOthersState() {
        const otherswrapper = this.othersWrapper.current;
        otherswrapper.classList.toggle("is-menu-enabled");
    }
    changeMailState() {
        const mailwrapper = this.mailWrapper.current;
        mailwrapper.classList.toggle("is-menu-enabled");
    }
    changeDlState() {
        const downloadwrapper = this.downloadWrapper.current;
        downloadwrapper.classList.toggle("is-menu-enabled");
    }


    /**
     * Envoie le PDF par mail
     */
    clickToMailPDF = async () => {
        await this.props.dispatch(changeTypeFileName("Pdf"));
        await this.props.handleChangeTitleName();
        await this.props.getPDF();

        const url = window.location.href.match(/(heures|avenant|devis|facture|vic|pgc|rj|contrat|calendrier|prediuo|diuo)/)?.[1];
        if (url) {
            await history.push(`/${url}/mail`);
        } else {
            console.error("Cannot determine appropriate URL");
        }
    }

    /**
     * Envoie le Excel par mail
     */
    clickToMailExcel = async () => {
        await this.props.dispatch(changeTypeFileName("Excel"));
        await this.props.handleChangeTitleName();
        await this.props.getSourceFile();

        const url = window.location.href.match(/(heures|avenant|devis|facture|vic|pgc|rj|contrat|calendrier|prediuo|diuo)/)?.[1];
        if (url) {
            await history.push(`/${url}/mail`);
        } else {
            console.error("Cannot determine appropriate URL");
        }
    }

    /**
     * Envoie le Word par mail
     */
    clickToMailWord = async () => {
        await this.props.dispatch(changeTypeFileName("Word"));
        await this.props.handleChangeTitleName();
        await this.props.getSourceFile();

        const url = window.location.href.match(/(heures|avenant|devis|facture|vic|pgc|rj|contrat|calendrier|prediuo|diuo)/)?.[1];
        if (url) {
            await history.push(`/${url}/mail`);
        } else {
            console.error("Cannot determine appropriate URL");
        }
    }

    /**
     * Envoie la relance par mail
     */
    clickToMailRelance = () => {
        if (this.props.match.params.doc === "devis") {
            history.push("/devis/relance/mail");
        }
        if (this.props.match.params.doc === "facture") {
            history.push("/facture/relance/mail");
        }
    }

    /**
     * Envoie la demande par mail
     */
    clickToMailDemande = () => {
        if (this.props.match.params.doc === "devis") {
            history.push("/devis/demande/mail");
        }
        if (this.props.match.params.doc === "facture") {
            history.push("/facture/demande/mail");
        }
    }

    render() {
        return (
            <div className="docmenu">
                <div className="others">
                    <Button onClick={this.changeOthersState}><MoreHorizIcon /><div className="barre"></div><KeyboardArrowDownIcon /></Button>
                    <div className="otherslist" ref={this.othersWrapper}>
                        <p onClick={this.handleOpenDialog}>Créer une relance</p>
                        <p onClick={this.clickToMailDemande}>Créer une demande</p>
                        <p>Mise en recouvrement</p>
                        <p onClick={this.downloadFile}>Envoyer un lien WebDocument</p>
                    </div>
                </div>
                <div className="mail">
                    <Button onClick={this.changeMailState}><MailIcon /><div className="barre"></div><KeyboardArrowDownIcon /></Button>
                    <div className="maillist" ref={this.mailWrapper}>
                        <p onClick={this.clickToMailPDF}>Envoyer en PDF</p>
                        {this.props.fileType === "excel" ? <p onClick={this.clickToMailExcel}>Envoyer en Excel</p> :
                            <p onClick={this.clickToMailWord}>Envoyer en Word</p>}
                    </div>
                </div>
                <div className="downloads">
                    <Button onClick={this.changeDlState}><GetAppIcon /><div className="barre"></div><KeyboardArrowDownIcon /></Button>
                    <div className="dllist" ref={this.downloadWrapper}>
                        <p onClick={() => this.downloadFile()}>Télécharger en PDF</p>
                        {this.props.fileType === "excel" ? <p onClick={() => this.downloadExcelFile()}>Télécharger en Excel</p> :
                            <p onClick={() => this.downloadWordFile()}>Télécharger en Word</p>}
                    </div>
                </div>
                <div className="print">
                    {/* <Button onClick={this.checkPdfFileAvailability(this.clickToPrint()).bind(this)}><PrintIcon /></Button> */}
                    <Button onClick={() => this.checkPdfFileAvailability(this.clickToPrint)}><PrintIcon /></Button>

                </div>
                <ConfirmRelance open={this.state.openDialog} onAutoConfirm={this.handleSubmit} onManConfirm={this.clickToMailRelance} setOpen={this.setOpen} />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    attachment: state.email.file,
    fileName: state.email.filename,
    dossierValue: state.devis.dossierValue,
    contact: state.contact.items,
    dossier: state.dossier.items,
    moInfos: state.contact.moInfos,
    dossierInfos: state.dossier.item,
});
export default connect(mapStateToProps)(DocMenu);