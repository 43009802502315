import React, { useEffect, useState, useCallback, useRef } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import coordogecLogo from './coordogecLogo.jpg';
import { format } from 'date-fns';
import { fetchDiuoByDoss } from "../../../redux/actions/diuoActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getPgcCoverPageData, getCoverPageData, genDiuoPDF, getDiuoFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import YesNoDialog from "../../YesNoDialog";
import "../../../Styles/DIUO/Page1.scss";

const DiuoPage1 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const diuo = useSelector((state) => state.diuo.diuoByDoss);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);
  const lastDiuoValueRef = useRef(diuoValue);
  
  const [date, setDate] = useState('')
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [constructionSite, setConstructionSite] = useState({
    category: '',
    operation: '',
    adress: '',
    cp: '',
    city: ''
  })

  const [projectOwner, setProjectOwner] = useState({
    name: '',
    complement: '',
    adress: '',
    cp: '',
    city: '',
    mail: '',
    tel: ''
  })

  const [projectManager, setProjectManager] = useState({
    name: '',
    complement: '',
    adress: '',
    cp: '',
    city: '',
    mail: '',
    tel: ''
  })

  const [office, setOffice] = useState({
    type: '',
    name: '',
    complement: '',
    adress: '',
    cp: '',
    city: '',
    mail: '',
    tel: ''
  })

  const [sps, setSps] = useState({
    name: 'COORDOGEC',
    complement: 'Jean-Marie SAINT-GERMAIN',
    adress: '4 rue des Artisans',
    cp: '93160',
    city: 'NOISY LE GRAND',
    mail: 'coordogec@coordogec.fr',
    tel: '01 43 03 63 17 – 06 80 68 14 82'
  })

  const [disableButton, setDisableButton] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const [imgCoverPage, setImgCoverPage] = useState(null);
  const [logoProjectOwner, setLogoProjectOwner] = useState(null);
  const [logoProjectManager, setLogoProjectManager] = useState(null);
  const [logoOffice, setLogoOffice] = useState(null);
  const [logoSps, setLogoSps] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const imageHandlers = {
    'cover_page.jpg': setImgCoverPage,
    'logo_project_owner.jpg': setLogoProjectOwner,
    'logo_project_manager.jpg': setLogoProjectManager,
    'logo_office.jpg': setLogoOffice,
    'logo_sps.jpg': setLogoSps
  };

  const fetchDataFromPgc = async () => {
    setIsLoading(true);
    resetCompaniesLogo();
    const pgcCoverPageData = await getPgcCoverPageData(dossierValue);

    if (Object.keys(pgcCoverPageData).length === 0) {
      unstable_batchedUpdates(() => {
        setConstructionSite({
          category: '',
          operation: '',
          adress: '',
          cp: '',
          city: ''
        })
        setProjectOwner({
          name: '',
          complement: '',
          adress: '',
          cp: '',
          city: '',
          mail: '',
          tel: ''
        })
        setProjectManager({
          name: '',
          complement: '',
          adress: '',
          cp: '',
          city: '',
          mail: '',
          tel: ''
        })
        setOffice({
          type: '',
          name: '',
          complement: '',
          adress: '',
          cp: '',
          city: '',
          mail: '',
          tel: ''
        })
        setShowInput(false);
        setDate('');
      })
      setIsLoading(false);
      return;
    };
    const constructionSiteLoaded = JSON.parse(pgcCoverPageData.construction_site)
    const projectOwnerLoaded = JSON.parse(pgcCoverPageData.project_owner)
    const projectManagerLoaded = JSON.parse(pgcCoverPageData.project_manager)
    const officeLoaded = JSON.parse(pgcCoverPageData.office)
    unstable_batchedUpdates(() => {
      setConstructionSite(constructionSiteLoaded)
      setProjectOwner(projectOwnerLoaded)
      setProjectManager(projectManagerLoaded)
      setOffice(officeLoaded)
      setShowInput((officeLoaded.type !== 'Contrôle' && officeLoaded.type !== 'Etude thermique' && officeLoaded.type !== '') ? true : false);
      setDate(format(new Date(), 'yyyy-MM-dd'));

      resetCompaniesLogo('coordogec');

      if (pgcCoverPageData.images.length > 0) {
        pgcCoverPageData.images.forEach((image) => {
          const buffer = new Uint8Array(image.img.buffer.data).buffer;
          const blob = new Blob([buffer]);
          const file = new File([blob], image.name, { type: 'image/png' });

          const handler = imageHandlers[image.name];
          if (handler) {
            handler(file);
          }
        });
      }
    })
    setIsLoading(false);
  };


const loadData = async () => {
  if (!diuoValue) {
    fetchDataFromPgc();
    return;
  }

  setDisableButton(true);
  setIsLoading(true);

  const response = await getCoverPageData(diuoValue);

  if (lastDiuoValueRef.current !== diuoValue) {
    setDisableButton(false);
    setIsLoading(false);
    return;
  }

  if (response) {
    const constructionSite = JSON.parse(response.construction_site);
    const projectOwner = JSON.parse(response.project_owner);
    const projectManager = JSON.parse(response.project_manager);
    const office = JSON.parse(response.office);
    const sps = JSON.parse(response.sps);
    const date = response.date;
    const images = response.images;

    unstable_batchedUpdates(() => {
      setConstructionSite(constructionSite);
      setProjectOwner(projectOwner);
      setProjectManager(projectManager);
      setOffice(office);
      setSps(sps);
      setDate(date);

      resetCompaniesLogo(sps.name.toLocaleLowerCase());

      if (images.length > 0) {
        images.forEach((image) => {
          const buffer = new Uint8Array(image.img.buffer.data).buffer;
          const blob = new Blob([buffer]);
          const file = new File([blob], image.name, { type: 'image/png' });

          const handler = imageHandlers[image.name];
          if (handler) {
            handler(file);
          }
        });
      }
      setShowInput((office.type !== 'Contrôle' && office.type !== 'Etude thermique' && office.type !== '') ? true : false);
      setIsLoading(false);
    });
  } else {
    fetchDataFromPgc();
  }
  setDisableButton(false);
  setIsLoading(false);
};

useEffect(() => {
  if (!dossierValue) return;

  lastDiuoValueRef.current = diuoValue;
  loadData();
}, [diuoValue, dossierValue]);

  const resetCompaniesLogo = (spsName) => {
    setImgCoverPage(null);
    setLogoProjectOwner(null);
    setLogoProjectManager(null);
    setLogoOffice(null);
    if (spsName === 'coordogec') {
      fetch(coordogecLogo)
        .then(response => response.blob())
        .then(blob => {
          const logoSps = new File([blob], 'coordogecLogo.jpg', { type: 'image/jpeg' });

          setLogoSps(logoSps);
        })
        .catch(error => console.error('Error loading default logo:', error));
    } else {
      setLogoSps(null);
    }
  };

  const incrementString = (str) => {
    if (!str) return 'A';
    let charCode = str.charCodeAt(str.length - 1);
    if (charCode === 90) {
      return incrementString(str.slice(0, -1)) + 'A';
    } else {
      return str.slice(0, -1) + String.fromCharCode(charCode + 1);
    }
  }

  const handleDeleteImg = useCallback((name) => {
    switch (name) {
      case "img-cover-page":
        setImgCoverPage(null);
        break;
      case "logo-project-owner":
        setLogoProjectOwner(null);
        break;
      case "logo-project-manager":
        setLogoProjectManager(null);
        break;
      case "logo-office":
        setLogoOffice(null);
        break;
      case "logo-sps":
        setLogoSps(null);
        break;
      default:
        break;
    }
  }, [diuoValue]);

  const handleCreateDiuo = async (e) => {
    e.preventDefault();
    setOpenDialogCreate(false);
    setIsLoading(true);

    // Générer un nouvel index
    const newIndex = incrementString(diuo.length > 0 ? diuo[diuo.length - 1].diuo_index : '');

    // Préparer les données du formulaire
    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', newIndex);
    formData.append('date', date);
    formData.append('constructionSite', JSON.stringify(constructionSite));
    formData.append('projectOwner', JSON.stringify(projectOwner));
    formData.append('projectManager', JSON.stringify(projectManager));
    formData.append('office', JSON.stringify(office));
    formData.append('sps', JSON.stringify(sps));

    // Ajouter les images et logos si disponibles
    if (imgCoverPage) formData.append('imgCoverPage', imgCoverPage);
    if (logoProjectOwner) formData.append('logoProjectOwner', logoProjectOwner);
    if (logoProjectManager) formData.append('logoProjectManager', logoProjectManager);
    if (logoOffice) formData.append('logoOffice', logoOffice);
    if (logoSps) formData.append('logoSps', logoSps);

    try {
      const response = await fetch('/api/diuo/create', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        dispatch(fetchDiuoByDoss(dossierValue));
      } else {
        console.error('Failed to upload images');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePgc = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Préparer les données du formulaire
    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', index);
    formData.append('diuoValue', diuoValue);
    formData.append('date', date);
    formData.append('constructionSite', JSON.stringify(constructionSite));
    formData.append('projectOwner', JSON.stringify(projectOwner));
    formData.append('projectManager', JSON.stringify(projectManager));
    formData.append('office', JSON.stringify(office));
    formData.append('sps', JSON.stringify(sps));

    // Ajouter les images et logos si disponibles
    if (imgCoverPage) formData.append('imgCoverPage', imgCoverPage);
    if (logoProjectOwner) formData.append('logoProjectOwner', logoProjectOwner);
    if (logoProjectManager) formData.append('logoProjectManager', logoProjectManager);
    if (logoOffice) formData.append('logoOffice', logoOffice);
    if (logoSps) formData.append('logoSps', logoSps);

    try {
      // Envoyer la requête au serveur
      const response = await fetch('/api/diuo/update', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Failed to upload images');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genDiuoPDF(diuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const nextPage = () => {
    history.push("/diuo/page2");
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOffice(prevOffice => ({
      ...prevOffice,
      [name]: value
    }));

    if (value === 'Autre...') {
      setShowInput(true);
      setOffice(prevOffice => ({
        ...prevOffice,
        [name]: ''
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOffice(prevOffice => ({
      ...prevOffice,
      [name]: value
    }));
  };

  const handleReturnToSelect = () => {
    setShowInput(false);
    setOffice(prevOffice => ({
      ...prevOffice,
      type: ''
    }));
  };

  return (
    <div className="diuo-page1">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Page de garde)</h1>
      <NumDossDIUO />
      <div className="container">
        <table className="custom-table">
          <tbody>
            <tr className="custom-cell">
              <td>Date</td>
              <td><input type="date" name="date" value={date} onChange={(e) => setDate(e.target.value)} /></td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>CHANTIER</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Dossier</td>
              <td><span>{dossierValue}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Indice</td>
              <td><span>{index}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Catégorie</td>
              <td><span>{constructionSite?.category || ''}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Opération</td>
              <td><span>{constructionSite?.operation || ''}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><span>{constructionSite?.adress ? `${constructionSite?.adress || ''}, ${constructionSite?.cp || ''} - ${constructionSite?.city || ''}` : ''}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Image</td>
              <td>
                <input
                  type="file"
                  name="img-cover-page"
                  key={`img-cp`}
                  multiple
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setImgCoverPage(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>MAÎTRE D'OUVRAGE</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><span>{projectOwner?.name || ''}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="complementMo" value={projectOwner?.complement || ''} placeholder="Facultatif" onChange={(e) => setProjectOwner(prevProjectOwner => ({ ...prevProjectOwner, complement: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><span>{projectOwner?.adress ? `${projectOwner?.adress || ''}, ${projectOwner?.cp || ''} - ${projectOwner?.city || ''}` : ''}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="mailMo" value={projectOwner?.mail || ''} onChange={(e) => setProjectOwner(prevProjectOwner => ({ ...prevProjectOwner, mail: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="telMo" value={projectOwner?.tel || ''} onChange={(e) => setProjectOwner(prevProjectOwner => ({ ...prevProjectOwner, tel: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-project-owner"
                  key={`logo-po`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoProjectOwner(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>MAÎTRE D’ŒUVRE</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><input type="text" name="nameMoe" value={projectManager?.name || '' || ''} onChange={(e) => setProjectManager(prevProjectManager => ({ ...prevProjectManager, name: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="nameMoeComplement" value={projectManager?.complement || '' || ''} placeholder="Facultatif" onChange={(e) => setProjectManager(prevProjectManager => ({ ...prevProjectManager, complement: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="adressMoe" value={projectManager?.adress || '' || ''} onChange={(e) => setProjectManager(prevProjectManager => ({ ...prevProjectManager, adress: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="cpMoe" value={projectManager?.cp || '' || ''} onChange={(e) => setProjectManager(prevProjectManager => ({ ...prevProjectManager, cp: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="cityMoe" value={projectManager?.city || '' || ''} onChange={(e) => setProjectManager(prevProjectManager => ({ ...prevProjectManager, city: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="mailMoe" value={projectManager?.mail || '' || ''} onChange={(e) => setProjectManager(prevProjectManager => ({ ...prevProjectManager, mail: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="telMoe" value={projectManager?.tel || '' || ''} onChange={(e) => setProjectManager(prevProjectManager => ({ ...prevProjectManager, tel: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-project-manager"
                  key={`logo-pm`}
                  multiple
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoProjectManager(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>BUREAU</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Type</td>
              <td>
                {!showInput ? (
                  <select name="type" value={office.type} onChange={handleChange}>
                    <option value=""></option>
                    <option value="Contrôle">Contrôle</option>
                    <option value="Etude thermique">Etude thermique</option>
                    <option value="Autre...">Autre...</option>
                  </select>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      name="type"
                      value={office.type}
                      onChange={handleInputChange}
                      style={{ width: '150px' }}
                      placeholder="Entrez un type de bureau"
                    />
                    <button
                      onClick={handleReturnToSelect}
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                    >
                      X
                    </button>
                  </div>
                )}
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><input type="text" name="nameOffice" value={office.name || ''} onChange={(e) => setOffice(prevOffice => ({ ...prevOffice, name: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="nameOfficeComplement" value={office.complement || ''} placeholder="Facultatif" onChange={(e) => setOffice(prevOffice => ({ ...prevOffice, complement: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="adressOffice" value={office.adress || ''} onChange={(e) => setOffice(prevOffice => ({ ...prevOffice, adress: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="cpOffice" value={office.cp || ''} onChange={(e) => setOffice(prevOffice => ({ ...prevOffice, cp: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="cityOffice" value={office.city || ''} onChange={(e) => setOffice(prevOffice => ({ ...prevOffice, city: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="mailOffice" value={office.mail || ''} onChange={(e) => setOffice(prevOffice => ({ ...prevOffice, mail: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="telOffice" value={office.tel || ''} onChange={(e) => setOffice(prevOffice => ({ ...prevOffice, tel: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-office"
                  key={`logo-of`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoOffice(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>COORDONNATEUR SPS</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Entreprise</td>
              <td><input type="text" name="nameSps" value={sps.name || ''} onChange={(e) => setSps(prevSps => ({ ...prevSps, name: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Contact</td>
              <td><input type="text" name="nameSpsComplement" value={sps.complement || ''} onChange={(e) => setSps(prevSps => ({ ...prevSps, complement: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="adressSps" value={sps.adress || ''} onChange={(e) => setSps(prevSps => ({ ...prevSps, adress: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="cpSps" value={sps.cp || ''} onChange={(e) => setSps(prevSps => ({ ...prevSps, cp: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="citySps" value={sps.city || ''} onChange={(e) => setSps(prevSps => ({ ...prevSps, city: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="mailSps" value={sps.mail || ''} onChange={(e) => setSps(prevSps => ({ ...prevSps, mail: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="telSps" value={sps.tel || ''} onChange={(e) => setSps(prevSps => ({ ...prevSps, tel: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-sps"
                  key={`logo-pm`}
                  multiple
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoSps(e.target.files[0])}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="imgs">
          {renderImageItem(imgCoverPage, "img-cover-page", handleDeleteImg, "img page de garde", "Page de garde")}
          {renderImageItem(logoProjectOwner, "logo-project-owner", handleDeleteImg, "logo maitre d ouvrage", "Maître d'ouvrage")}
          {renderImageItem(logoProjectManager, "logo-project-manager", handleDeleteImg, "logo maitre d oeuvre", "Maître d'oeuvre")}
          {renderImageItem(logoOffice, "logo-office", handleDeleteImg, "logo bureau", "Bureau")}
          {renderImageItem(logoSps, "logo-sps", handleDeleteImg, "logo sps", "Coordonnateur")}
        </div>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenDialogCreate(true)}
          disabled={disableButton}
        >
          Nouveau DIUO
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdatePgc}
          disabled={!diuoValue || disableButton}
        >
          Modifier
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={!diuoValue || disableButton}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={!diuoValue || disableButton}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={!diuoValue || disableButton}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <YesNoDialog
        isOpen={openDialogCreate}
        title={'CRÉATION DIUO'}
        question={`Voulez-vous créer un nouvel indice DIUO du dossier ${dossierValue} ?`}
        handleYesClick={handleCreateDiuo}
        handleClose={() => setOpenDialogCreate(false)}
      />
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};
const renderImageItem = (image, name, handleDelete, altText, description) => (
  image && (
    <div className="img-item">
      <IconButton
        className="item-delete"
        style={{
          color: "white",
          position: "absolute",
          top: 0,
          right: 0
        }}
        onClick={() => handleDelete(name)}
      >
        <HighlightOffIcon />
      </IconButton>
      <img
        src={URL.createObjectURL(image)}
        alt={altText}
        style={{ height: "200px" }}
      />
      <p>{description}</p>
    </div>
  )
);

export default DiuoPage1;