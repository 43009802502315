import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getMeetingReportData, genDiuoPDF, getDiuoFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import "../../../Styles/DIUO/Page9.scss";

const initialMeetingReport = { company: '', name: '', adress: '', observations: '' }
const DiuoPage9 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [meetingReport, setMeetingReport] = useState(initialMeetingReport);
  const [signature, setSignature] = useState(false);

  const [annexImages, setAnnexImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const extractNumber = (name) => {
    const match = name.match(/annexe_(\d+)\.jpg/);
    return match ? parseInt(match[1], 10) : 0;
  };

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!index || !diuoValue) {
        setMeetingReport(initialMeetingReport);
        setSignature(false);
        setAnnexImages([]);
        return
      }
      const response = await getMeetingReportData(dossierValue, index, diuoValue);

      const meetingReportLoaded = response.meetingReport;
      const signatureLoaded = response.signature;

      if (response.images.length > 0) {
        const tempImages = [];
        response.images
          .sort((a, b) => {
            const numA = extractNumber(a.name);
            const numB = extractNumber(b.name);
            return numA - numB;
          })
          .forEach((image) => {
            const buffer = new Uint8Array(image.img.buffer.data).buffer;
            const blob = new Blob([buffer]);
            const file = new File([blob], image.name, { type: 'image/png' });

            tempImages.push(file);
          });
        setAnnexImages(tempImages);
      } else {
        setAnnexImages([]);
      }
      setMeetingReport(meetingReportLoaded);
      setSignature(signatureLoaded);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [diuoValue]);

  useEffect(() => {
    if (!dossierValue) return
    loadData()
  }, [diuoValue]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAnnexImages(prevImages => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setAnnexImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', index);
    formData.append('diuoValue', diuoValue);
    formData.append('meetingReport', JSON.stringify(meetingReport));
    formData.append('signature', signature ? 1 : 0);

    if (annexImages.length > 0) {
      annexImages.forEach((file, index) => {
        formData.append(`annexe_${index + 1}`, file);
      })
    }

    try {
      const response = await fetch('/api/diuo/saveMeetingReport', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Failed to save');
      }
    } catch (error) {
      console.error('Error update:', error);
    } finally {
      setIsLoading(false);
      loadData()
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genDiuoPDF(diuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page8");
  }

  const nextPage = () => {
    history.push("/diuo/page10");
  }

  return (
    <div className="diuo-page9">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Procès-verbal)</h1>
      <NumDossDIUO />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>PROCES-VERBAL</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td style={{ width: "30%" }}>Maitre d’ouvrage</td>
              <td>
                <input type="text" name="adress" value={meetingReport?.company} onChange={(e) => setMeetingReport(prevState => ({ ...prevState, company: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Contact</td>
              <td>
                <input type="text" name="operation" value={meetingReport?.name} onChange={(e) => setMeetingReport(prevState => ({ ...prevState, name: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Site concerné</td>
              <td>
                <input type="text" name="buildingPurpose" value={meetingReport?.adress} onChange={(e) => setMeetingReport(prevState => ({ ...prevState, adress: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Observations éventuelles</td>
              <td>
                <textarea value={meetingReport?.observations} onChange={(e) => setMeetingReport(prevState => ({ ...prevState, observations: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Signature</td>
              <td>
                <input type="checkbox" checked={signature} onChange={(e) => setSignature(e.target.checked)} />
              </td>
            </tr>
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>ANNEXES</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td style={{ width: "30%" }}>Ajouter une annexe</td>
              <td>
                <input
                  type="file"
                  name="annex-img"
                  key={`img-ai`}
                  multiple
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleFileChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {annexImages.length > 0 &&
          <div className="img-item">
            {annexImages.map((annexImage, index) => (
              <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '10px', textAlign: 'center' }}>
                <IconButton
                  className="item-delete"
                  style={{
                    color: "white",
                    position: "absolute",
                    top: 0,
                    right: 0
                  }}
                  onClick={() => handleRemoveImage(index)}
                >
                  <HighlightOffIcon />
                </IconButton>
                <img
                  src={URL.createObjectURL(annexImage)}
                  alt={`annexe-img-${index}`}
                  style={{ height: "200px" }}
                />
                <p>Annexe {index + 1}</p>
              </div>
            ))}
          </div>}
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Validation
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default DiuoPage9;