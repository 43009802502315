export const FETCH_PGCBYDOSS_BEGIN = 'FETCH_PGCBYDOSS_BEGIN';
export const FETCH_PGCBYDOSS_SUCCESS = 'FETCH_PGCBYDOSS_SUCCESS';
export const FETCH_PGCBYDOSS_FAILURE = 'FETCH_PGCBYDOSS_FAILURE';
export const FETCH_PGCBYDOSSVERIF_BEGIN = 'FETCH_PGCBYDOSSVERIF_BEGIN';
export const FETCH_PGCBYDOSSVERIF_SUCCESS = 'FETCH_PGCBYDOSSVERIF_SUCCESS';
export const FETCH_PGCBYDOSSVERIF_FAILURE = 'FETCH_PGCBYDOSSVERIF_FAILURE';
export const CHANGE_INDEX_VALUE = 'CHANGE_INDEX_VALUE';

export function fetchPgcByDoss(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({
      ndossier: ndossier
    })
  };
  return dispatch => {
    dispatch(fetchPgcBegin());
    return fetch("/api/pgc/getPgcByDoss", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchPgcSuccess(json));
      })
      .catch(error => dispatch(fetchPgcFailure(error)));
  };
}

export function fetchPgcByDossVerif(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({
      ndossier: ndossier
    })
  };
  return dispatch => {
    dispatch(fetchPgcVerifBegin());
    return fetch("/api/pgc/getPgcByDoss", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchPgcVerifSuccess(json));
      })
      .catch(error => dispatch(fetchPgcVerifFailure(error)));
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function newPgcIndexValue(newValue) {
  return dispatch => dispatch(changePgcIndexValue(newValue))
}

export const fetchPgcBegin = () => ({
  type: FETCH_PGCBYDOSS_BEGIN
});

export const fetchPgcSuccess = data => ({
  type: FETCH_PGCBYDOSS_SUCCESS,
  payload: { pgcByDoss: data }
});

export const fetchPgcFailure = error => ({
  type: FETCH_PGCBYDOSS_FAILURE,
  payload: { error }
});

export const fetchPgcVerifBegin = () => ({
  type: FETCH_PGCBYDOSSVERIF_BEGIN
});

export const fetchPgcVerifSuccess = data => ({
  type: FETCH_PGCBYDOSSVERIF_SUCCESS,
  payload: { pgcByDossVerif: data }
});

export const fetchPgcVerifFailure = error => ({
  type: FETCH_PGCBYDOSSVERIF_FAILURE,
  payload: { error }
});

export const changePgcIndexValue = index => ({
  type: CHANGE_INDEX_VALUE,
  payload: { index }
});