import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { ELEMENT_CONFIGS, CONSTRUCTION_ELEMENTS, DEFAULT_ELEMENT_CONFIG } from "./Const";
import { dataUtils } from "./dataStructureUtils";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import DeleteIcon from '@material-ui/icons/Delete';
import "../../../Styles/PreDIUO/ConstructionElements.scss";

const ConstructionElementTable = ({ isVisible, title, onInputChange, elementData = {}, onAddInformationRow, onDeleteInformationRow }) => {
  if (!isVisible) return null;

  const config = elementData.config || DEFAULT_ELEMENT_CONFIG;

  if (config.type === "text") {
    return (
      <div className={`construction-elements__tables-container ${isVisible ? "visible" : ""}`}>
        <h3 className="construction-elements__tables-title">{title}</h3>
        <div
          className="construction-elements__text-content"
          dangerouslySetInnerHTML={{
            __html: config.content
              .replace(/·/g, '<span style="font-size: 2em; vertical-align: middle; margin-right: 10px;">·</span>')
              .replace(/\*\*\*(.*?)\*\*\*/g, '<strong>$1</strong>'),
          }}
          style={{ whiteSpace: "pre-line" }}
        />
      </div>
    );
  }

  return (
    <div className={`construction-elements__tables-container ${isVisible ? "visible" : ""}`}>
      <h3 className="construction-elements__tables-title">{title}</h3>

      {config.quantities && title !== 'Permis feu' && title !== 'Bruits et vibrations' && (
        <table className="table table--quantities">
          <thead className="table__header">
            <tr>
              {config.quantities.headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {(elementData.quantities || []).map((row) => (
              <tr key={row.rowIndex}>
                {row.values.map((cell) => (
                  <td key={cell.columnIndex}>
                    <input
                      type="text"
                      value={cell.value || ""}
                      onChange={(e) =>
                        onInputChange(title, "quantities", row.rowIndex, cell.columnIndex, e.target.value)
                      }
                      style={{ textAlign: "center" }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {config.informations && (
        <table className="table">
          <thead className="table__header">
            <tr>
              {config.informations.headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody className="table__body">
            {(elementData.informations || []).map((row) => (
              <tr key={row.rowIndex}>
                <td>
                  <input
                    type="text"
                    value={row.text || ""}
                    onChange={(e) =>
                      onInputChange(title, "informations", row.rowIndex, 0, e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.value || ""}
                    onChange={(e) =>
                      onInputChange(title, "informations", row.rowIndex, 1, e.target.value)
                    }
                    style={{ textAlign: "center" }}
                  />
                </td>
                <td style={{ textAlign: "center", width: '50px' }}>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => onDeleteInformationRow(title, row.rowIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={2} style={{ height: "40px" }}>
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => onAddInformationRow(title)}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

const ConstructionElements = ({ onDataChange, constructionData }) => {
  const [formData, setFormData] = useState({
    selectedElements: Object.fromEntries(CONSTRUCTION_ELEMENTS.map((element) => [element, false])),
    elementsData: {},
  });
  const [newElementTitle, setNewElementTitle] = useState("");

  useEffect(() => {
    if (constructionData) {
      setFormData(constructionData);
    }
  }, [constructionData]);

  useEffect(() => {
    onDataChange(formData);
  }, [formData, onDataChange]);

  const handleCheckboxChange = (element) => {
    setFormData((prev) => {
      const newSelectedElements = {
        ...prev.selectedElements,
        [element]: !prev.selectedElements[element],
      };

      let newElementsData = { ...prev.elementsData };

      if (newSelectedElements[element]) {
        const config = ELEMENT_CONFIGS[element] || DEFAULT_ELEMENT_CONFIG;
        newElementsData[element] = dataUtils.initializeElementData(config);
      } else {
        delete newElementsData[element];
      }

      return {
        selectedElements: newSelectedElements,
        elementsData: newElementsData,
      };
    });
  };

  const handleInputChange = (element, tableType, rowIndex, columnIndex, value) => {
    setFormData((prev) => {
      const elementData = prev.elementsData[element] || dataUtils.initializeElementData(ELEMENT_CONFIGS[element] || DEFAULT_ELEMENT_CONFIG);

      const updatedElementData = dataUtils.updateElementValue(
        elementData,
        tableType,
        rowIndex,
        columnIndex,
        value
      );

      return {
        ...prev,
        elementsData: {
          ...prev.elementsData,
          [element]: updatedElementData,
        },
      };
    });
  };

  const handleAddNewElement = () => {
    if (!newElementTitle.trim()) return;

    setFormData((prev) => {
      const newSelectedElements = {
        ...prev.selectedElements,
        [newElementTitle]: true,
      };

      const newElementsData = {
        ...prev.elementsData,
        [newElementTitle]: dataUtils.initializeElementData(DEFAULT_ELEMENT_CONFIG),
      };

      setNewElementTitle("");

      return {
        selectedElements: newSelectedElements,
        elementsData: newElementsData,
      };
    });
  };

  const handleAddInformationRow = (element) => {
    setFormData((prev) => {
      const elementData = prev.elementsData[element] || dataUtils.initializeElementData(ELEMENT_CONFIGS[element] || DEFAULT_ELEMENT_CONFIG);

      const newInformations = [
        ...elementData.informations,
        { rowIndex: elementData.informations.length, text: "", value: "" },
      ];

      return {
        ...prev,
        elementsData: {
          ...prev.elementsData,
          [element]: {
            ...elementData,
            informations: newInformations,
          },
        },
      };
    });
  };

  const handleDeleteInformationRow = (element, rowIndex) => {
    setFormData((prev) => {
      const elementData = prev.elementsData[element];
      if (!elementData) return prev;

      const newInformations = elementData.informations.filter(
        (row) => row.rowIndex !== rowIndex
      ).map((row, idx) => ({
        ...row,
        rowIndex: idx
      }));

      return {
        ...prev,
        elementsData: {
          ...prev.elementsData,
          [element]: {
            ...elementData,
            informations: newInformations,
          },
        },
      };
    });
  };

  return (
    <div className="construction-elements">
      <h2 className="construction-elements__title">Éléments de construction</h2>

      <div className="construction-elements__add-new">
        <input
          type="text"
          placeholder="Nouvel élément"
          value={newElementTitle}
          onChange={(e) => setNewElementTitle(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddNewElement();
            }
          }}
          style={{ boxShadow: "0 0 7px #7a7a7a" }}
        />
        <Button
          variant="contained"
          onClick={handleAddNewElement}
        >
          Ajouter
        </Button>
      </div>

      <div className="construction-elements__checklist">
        {Object.keys(formData.selectedElements).map((element) => (
          <div key={element} className="construction-elements__checklist-item">
            <input
              type="checkbox"
              id={element}
              checked={formData.selectedElements[element]}
              onChange={() => handleCheckboxChange(element)}
            />
            <label htmlFor={element} className="construction-elements__label">
              {element}
            </label>
          </div>
        ))}
      </div>

      <div className="construction-elements__tables">
        {Object.keys(formData.selectedElements).map((element) => (
          <ConstructionElementTable
            key={element}
            title={element}
            isVisible={formData.selectedElements[element]}
            onInputChange={handleInputChange}
            elementData={formData.elementsData[element]}
            onAddInformationRow={handleAddInformationRow}
            onDeleteInformationRow={handleDeleteInformationRow}
          />
        ))}
      </div>
    </div>
  );
};

export default ConstructionElements;