import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { fetchPreDiuoByDoss } from "../../../redux/actions/preDiuoActions";
import { genPreDiuoPDF, getPreDiuoFile, deletePreDiuo } from "../Utils";
import { Button } from "@material-ui/core";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPreDiuo from "../../NumDossPreDiuo";
import YesNoDialog from "../../YesNoDialog";
import GenPdf from "../../GenPdf";
import "../../../Styles/PreDIUO/Validation.scss";

const PreDiuoPage4 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.preDiuo.index);
  const preDiuoValue = useSelector((state) => state.preDiuo.preDiuoValue);

  const [disableButton, setDisableButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleDeletePreDiuo = async () => {
    setOpenDialog(false);
    setDisableButton(true);
    try {
      const result = await deletePreDiuo(dossierValue, index, preDiuoValue);
      if (result.success) {
        dispatch(fetchPreDiuoByDoss(dossierValue));
      }
    } catch (err) {
      console.error("Erreur lors de la suppression du Pre DIUO :", err.message);
    } finally {
      setDisableButton(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPreDiuoPDF(preDiuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`Pré DIUO`));
  }, [preDiuoValue]);

  return (
    <div className="pre-diuo-validation">
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Pré DIUO (Validation)</h1>
      <NumDossPreDiuo />
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !preDiuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleShowPdf}
          disabled={disableButton || !preDiuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
          disabled={disableButton || !preDiuoValue}
        >
          Supprimer Pré DIUO
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
        <YesNoDialog
          isOpen={openDialog}
          title={'SUPPRESSION Pré DIUO'}
          question={`Êtes-vous sûr de vouloir supprimer le Pré DIUO indice "${index}" du dossier ${dossierValue} ?`}
          handleYesClick={handleDeletePreDiuo}
          handleClose={() => setOpenDialog(false)}
        />
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default PreDiuoPage4;