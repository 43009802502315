import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { newDossierValue } from "../redux/actions/devisActions";
import { fetchPgcByDoss, fetchPgcByDossVerif, newPgcIndexValue } from "../redux/actions/pgcActions";
import "../Styles/PGC/NumDossPGC.scss";

const useDossier = () => useSelector((state) => state.dossier.items, shallowEqual);
const usePgc = () => useSelector((state) => state.pgc.pgcByDoss, shallowEqual);

const NumDossPGC = () => {
  const dispatch = useDispatch();

  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const dossier = useDossier();
  const pgc = usePgc();
  const index = useSelector((state) => state.pgc.index);
  const pgcValue = useSelector((state) => state.pgc.pgcValue);

  const handleDossierChange = useCallback((event) => {
    const value = event.target.value;
    dispatch(newPgcIndexValue(''));
    dispatch(newDossierValue(value));
    dispatch(fetchPgcByDoss(value));
  }, [dispatch]);

  const handleIndexChange = useCallback((event) => {
    const value = event.target.value;
    dispatch(newPgcIndexValue(value));
  }, [dispatch]);

  useEffect(() => {
    if (!dossierValue) return
    dispatch(fetchPgcByDossVerif(dossierValue));
  }, []);

  useEffect(() => {
    if (dossierValue && !pgcValue) {
      dispatch(fetchPgcByDoss(dossierValue));
    }
  }, [dossierValue, pgcValue, dispatch]);

  const dossierOptions = useMemo(() => (
    dossier.map(({ ndossier }) => (
      <option key={ndossier} value={ndossier}>
        {ndossier}
      </option>
    ))
  ), [dossier]);

  const pgcOptions = useMemo(() => (
    pgc.map(({ pgc_index }) => (
      <option key={pgc_index} value={pgc_index}>
        {pgc_index}
      </option>
    ))
  ), [pgc]);

  return (
    <div className="pgc-menu">
      <div className="ndossier">
        <label htmlFor="dossier">N° Dossier : </label>
        <select
          id="dossier"
          onChange={handleDossierChange}
          value={dossierValue}
        >
          {dossierOptions}
        </select>
      </div>
      <div className="indice">
        <label htmlFor="index">Indice : </label>
        <select
          id="index"
          onChange={handleIndexChange}
          value={index}
        >
          {pgcOptions}
        </select>
      </div>
    </div>
  );
};

export default React.memo(NumDossPGC);
