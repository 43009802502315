import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getTechnicalLotsData, genDiuoPDF, getDiuoFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import "../../../Styles/DIUO/Page5.scss";

const technicalLotsConst = {
  index: '',
  works: '',
  company: '',
  contact: '',
  vic: false,
  ppsps: false
};

const DiuoPage5 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [technicalLots, setTechnicalLots] = useState([technicalLotsConst]);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    try {
      if (!index || !diuoValue) {
        setTechnicalLots([technicalLotsConst]);
        return
      }
      const response = await getTechnicalLotsData(diuoValue);
      const parsedResponse = response ? JSON.parse(response) : [technicalLotsConst];
      setTechnicalLots(parsedResponse);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!dossierValue) return
    loadData()
  }, [diuoValue]);

  const handleTechnicalLotsValues = (event, index, prevValue) => {
    const { name, value, checked } = event.target;

    setTechnicalLots(prevState => {
      const element = [...prevState];
      element[index] = {
        ...element[index],
        [name]: (name === 'ppsps' || name === 'vic') ? !prevValue : checked === undefined ? value : name === "index" ? value : checked
      }
      return element
    })
  }

  const handleAddTechnicalLot = () => {
    setTechnicalLots(prevState => [...prevState, technicalLotsConst])
  }

  const handleDeleteTechnicalLot = (index) => {
    if (technicalLots.length === 1) {
      setTechnicalLots([technicalLotsConst])
    } else {
      const newArray = [...technicalLots];
      newArray.splice(index, 1);
      setTechnicalLots(newArray);
    }
  }

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        diuoValue,
        technicalLots: JSON.stringify(technicalLots)
      }),
    };

    try {
      const response = await fetch('/api/diuo/saveTechnicalLots', requestOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to save technical lots');
      }
    } catch (err) {
      console.error('Error saving technical lots:', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genDiuoPDF(diuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page4");
  }

  const nextPage = () => {
    history.push("/diuo/page6");
  }

  return (
    <div className="diuo-page5">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Lots techniques)</h1>
      <NumDossDIUO />
      <div className="container">

        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={7}><strong>LISTE DES LOTS TECHNIQUES</strong></td>
            </tr>
            <tr className="custom-cell-title-lot">
              <td className="custom-cell-label-lot" style={{ borderLeft: '1px solid #c2c2c2fd' }} rowSpan={2}><strong>LOT</strong></td>
              <td className="custom-cell-label-lot" rowSpan={2}><strong>TRAVAUX </strong></td>
              <td className="custom-cell-label-lot" rowSpan={2}><strong>NOM ET ADRESSE<br />ENTREPRISE</strong></td>
              <td className="custom-cell-label-lot" rowSpan={2}><strong>CONTACT</strong></td>
              <td className="custom-cell-label-lot" style={{ width: '50px' }}><strong>VIC</strong></td>
              <td className="custom-cell-label-lot" style={{ width: '50px' }}><strong>PPSPS</strong></td>
              <td className="custom-cell-label-lot" style={{ borderRight: '1px solid #c2c2c2fd' }} rowSpan={2}></td>
            </tr>
            <tr className="custom-cell-title-lot">
              <td className="custom-cell-label-lot" colSpan={2}><strong style={{ color: 'green' }}>OUI</strong><strong style={{ color: 'white' }}> / </strong><strong style={{ color: 'red' }}>NON</strong></td>
            </tr>
          </thead>
          <tbody>
            {technicalLots.map((item, i) => (
              <tr className="custom-cell-lot" key={`technical-lots-${i}`}>
                <td className="custom-cell-label-lot"><input type="text" name="index" value={item.index} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="0..." /></td>
                <td className="custom-cell-label-lot"><textarea name="works" value={item.works} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="Lot" /></td>
                <td className="custom-cell-label-lot"><textarea name="company" value={item.company} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="Entreprise Dupont&#10;123 Rue de la République&#10;75001 - Paris" /></td>
                <td className="custom-cell-label-lot"><textarea name="contact" value={item.contact} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="Jean Dupont&#10;01 23 45 67 89&#10;jean.dupont@exemple.fr" /></td>
                <td className="custom-cell-label-lot"><input type="checkbox" name="vic" checked={true} onChange={(event) => handleTechnicalLotsValues(event, i, item.vic)} style={{ width: '17px', height: '17px', accentColor: item.vic ? 'green' : 'red' }} /></td>
                <td className="custom-cell-label-lot"><input type="checkbox" name="ppsps" checked={true} onChange={(event) => handleTechnicalLotsValues(event, i, item.ppsps)} style={{ width: '17px', height: '17px', accentColor: item.ppsps ? 'green' : 'red' }} /></td>
                <td className="custom-cell-label-lot">
                  <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="delete"
                    size="small"
                    className="delete"
                    disabled={technicalLots.length === 1 && technicalLots.every((item) => JSON.stringify(item) === JSON.stringify(technicalLotsConst))}
                    onClick={() => handleDeleteTechnicalLot(i)}
                  >
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            ))}
            <tr className="add-technical-lot">
              <td>
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddTechnicalLot}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default DiuoPage5;