export const FETCH_PREDIUOBYDOSS_BEGIN = 'FETCH_PREDIUOBYDOSS_BEGIN';
export const FETCH_PREDIUOBYDOSS_SUCCESS = 'FETCH_PREDIUOBYDOSS_SUCCESS';
export const FETCH_PREDIUOBYDOSS_FAILURE = 'FETCH_PREDIUOBYDOSS_FAILURE';
export const FETCH_PREDIUOBYDOSSVERIF_BEGIN = 'FETCH_PREDIUOBYDOSSVERIF_BEGIN';
export const FETCH_PREDIUOBYDOSSVERIF_SUCCESS = 'FETCH_PREDIUOBYDOSSVERIF_SUCCESS';
export const FETCH_PREDIUOBYDOSSVERIF_FAILURE = 'FETCH_PREDIUOBYDOSSVERIF_FAILURE';
export const CHANGE_INDEX_VALUE = 'CHANGE_INDEX_VALUE';

export function fetchPreDiuoByDoss(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({
      ndossier: ndossier
    })
  };
  return dispatch => {
    dispatch(fetchPreDiuoBegin());
    return fetch("/api/prediuo/getPreDiuoByDoss", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchPreDiuoSuccess(json));
      })
      .catch(error => dispatch(fetchPreDiuoFailure(error)));
  };
}

export function fetchPreDiuoByDossVerif(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({
      ndossier: ndossier
    })
  };
  return dispatch => {
    dispatch(fetchPreDiuoVerifBegin());
    return fetch("/api/prediuo/getPreDiuoByDoss", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchPreDiuoVerifSuccess(json));
      })
      .catch(error => dispatch(fetchPreDiuoVerifFailure(error)));
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function newPreDiuoIndexValue(newValue) {
  return dispatch => dispatch(changePreDiuoIndexValue(newValue))
}

export const fetchPreDiuoBegin = () => ({
  type: FETCH_PREDIUOBYDOSS_BEGIN
});

export const fetchPreDiuoSuccess = data => ({
  type: FETCH_PREDIUOBYDOSS_SUCCESS,
  payload: { preDiuoByDoss: data }
});

export const fetchPreDiuoFailure = error => ({
  type: FETCH_PREDIUOBYDOSS_FAILURE,
  payload: { error }
});

export const fetchPreDiuoVerifBegin = () => ({
  type: FETCH_PREDIUOBYDOSSVERIF_BEGIN
});

export const fetchPreDiuoVerifSuccess = data => ({
  type: FETCH_PREDIUOBYDOSSVERIF_SUCCESS,
  payload: { preDiuoByDossVerif: data }
});

export const fetchPreDiuoVerifFailure = error => ({
  type: FETCH_PREDIUOBYDOSSVERIF_FAILURE,
  payload: { error }
});

export const changePreDiuoIndexValue = index => ({
  type: CHANGE_INDEX_VALUE,
  payload: { index }
});