import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from 'react-redux';
import { fetchEcheances, changeAmendmentValue, fetchEcheancesByAmendment } from '../../../redux/actions/echeancesActions';
import { fetchAllFac } from '../../../redux/actions/facActions';
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import GenPdf from '../../GenPdf';
import Header from '../../Header/Header';
import NumDoss from '../../NumDoss';
import DocMenu from "../../DocMenu";
import YesNoDialog from '../../YesNoDialog';
import { getDossier, getContactInfo } from '../Utils';
import ContratEchTable from './ContratEchTable';
import "../../../Styles/Contrat/ContratEch.scss";


class ContratEch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            datepgcp: "",
            datepgce: "",
            datedem: "",
            dates: [],
            adresse: "",
            ville: "",
            cp: "",
            comp: "",
            openDialogCreate: false,
            openDialogDeleteAmendment: false,
            openDialogGen: false,
            isFileOpen: false,
            file: null,
            disableButton: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleValidClick = this.handleValidClick.bind(this)
        this.handleDeleteAmendment = this.handleDeleteAmendment.bind(this)
        this.handleUpdateClick = this.handleUpdateClick.bind(this)
        this.handleGenClick = this.handleGenClick.bind(this)
    }
    /**
     * Initialisation des dates échéancières du dossier choisi
     */
    async componentDidMount() {
        const { dossierValue, amendment, dossier } = this.props
        await this.props.dispatch(changePdfFileName(""));
        await this.props.dispatch(fetchEcheances())
        await this.props.dispatch(fetchEcheancesByAmendment(dossierValue, amendment))
        await this.loadData(dossier, dossierValue)
    }
    /**
     * Mise à jour des dates échéancières lors du changement de dossier 
     * @param {{}} prevProps 
     */
    async componentDidUpdate(prevProps) {
        const { dossierValue, amendment, dossier } = this.props
        if (dossierValue != prevProps.dossierValue || amendment != prevProps.amendment) {
            await this.props.dispatch(changePdfFileName(""));
            await this.props.dispatch(fetchEcheancesByAmendment(dossierValue, amendment))
            await this.loadData(dossier, dossierValue)
        }
    }

    loadData(dossiers, dossierValue) {
        const { echeance, contact } = this.props
        if (Object.keys(echeance).length > 0) {
            let datedem = echeance.datedem.slice(0, 10)
            let datepgcp = echeance.datepgcp.slice(0, 10)
            let datepgce = echeance.datepgce.slice(0, 10)
            let dates = JSON.parse(echeance.dates)
            this.setState({ datepgcp, datepgce, datedem, dates })
        } else {
            this.setState({ datepgcp: "", datepgce: "", datedem: "", adresse: "", ville: "", cp: "", comp: "", dates: [] })
        }


        let currentFolder = getDossier(dossiers, dossierValue)
        let idcontact = currentFolder.idcontact;
        let currentContact = getContactInfo(contact, idcontact);
        if (currentContact) {
            this.setState({
                adresse: currentContact.adress,
                ville: currentContact.city,
                cp: currentContact.cp,
                comp: currentFolder.sci_sccv,
                file: null
            })
        }
    }
    /**
     * Permet de changer les échéances du dossier
     * @param {{}} event 
     */
    handleChange(event, index) {
        if ([event.target.name][0] == `Date${index}`) {
            this.setEch(this.state.dates, event.target.value, index)
        } else if ([event.target.name][0] == "datedem") {
            this.setEchByDateDem(this.state.dates, event.target.value)
        } else this.setState({ [event.target.name]: event.target.value })
    }


    getDevisCon() {
        var arr = [];
        for (let i = 0; i < this.props.deviscon.length; i++) {
            if (
                this.props.deviscon[i].ndossier == this.props.dossierValue
            ) {
                arr.push(this.props.deviscon[i]);
            }
        }
        var max = Math.max.apply(
            Math,
            arr.map((item) => item.iddeviscon)
        );
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].iddeviscon == max) {
                return arr[i];
            }
        }
        return "";
    }


    getDevisRea() {
        var arr = [];
        for (let i = 0; i < this.props.devisrea.length; i++) {
            if (
                this.props.devisrea[i].ndossier == this.props.dossierValue
            ) {
                arr.push(this.props.devisrea[i]);
            }
        }
        var max = Math.max.apply(
            Math,
            arr.map((item) => item.iddevisrea)
        );
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].iddevisrea == max) {
                return arr[i];
            }
        }
        return "";
    }

    getDossier() {
        for (let i = 0; i < this.props.dossier.length; i++) {
            if (this.props.dossier[i].ndossier == this.props.dossierValue) {
                return this.props.dossier[i];
            }
        }
        return "";
    }

    getEchNum(paymentMethod, duree) {
        if (paymentMethod == 26) {
            return Math.ceil(duree / 2) + " échéances bimestrielles";
        } else return duree + " échéances mensuelles";
    }

    getEchPrice(paymentMethod, duree) {
        if (paymentMethod == 26) {
            return this.getDevisRea().totalht / Math.ceil(duree / 2)
        } else return ((this.getDevisRea().totalht + this.getDevisCon().totalht) * 0.66) / duree
    }


    /**
     * Génère les échéances de Réalisation en fonction de la date de début de chantier
     * @param {Date} reaech 
     * @param {*} event 
     */

    setEchByDateDem(datesArray, eventValue) {
        const intervalInMonths = this.props.echeance.payment_method == 26 ? 2 : 1
        let dates = [...datesArray]
        for (let i = 0; i < dates.length; i++) {
            let date = i === 0 ? new Date(eventValue) : new Date(dates[i - 1].dateech)
            date.setMonth(date.getMonth() + intervalInMonths)
            dates[i].dateech = `${date.toISOString().slice(0, 8)}02`
        }
        this.setState({ datedem: eventValue, dates })
    }

    setEch(datesArray, eventValue, index) {
        let dates = [...datesArray]
        const day = new Date(eventValue).getDate()
        for (let i = index; i < dates.length; i++) {
            if (i == index) {
                dates[i].dateech = eventValue
            } else {
                let newDate = new Date(dates[i].dateech)
                newDate.setDate(day)
                dates[i].dateech = newDate.toISOString().slice(0, 10)
            }
        }
        this.setState({ dates })
    }
    /**
     * Validation des échéances rentrées par l'utilisateur
     */
    async handleValidClick() {
        this.handleCloseCreate()

        let amendment = 1;
        const ndossier = this.props.dossierValue;
        let today = new Date().toISOString().slice(0, 10);
        this.setState({ disableButton: true })

        const json = {
            ndossier: ndossier,
            datepgcp: this.state.datepgcp,
            datepgce: this.state.datepgce,
            datedem: this.state.datedem,
            payment_method: this.props.echeance.payment_method,
            dates: this.state.dates,
            created_date: today
        };

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(json)
        };

        await fetch("/api/contrat/registerEch", requestOptions)
            .then((res) => res.json())
            .then((res) => {
                amendment = res.amendment
            })
            .catch((err) => {
                console.log(err);
            });

        await this.props.dispatch(fetchEcheances());
        await this.props.dispatch(changeAmendmentValue(amendment));
        await this.setState({ disableButton: false })
    }


    async handleUpdateClick() {
        const { dossierValue, amendment, echeance } = this.props
        const { datepgcp, datepgce, datedem, dates } = this.state
        this.setState({ disableButton: true });

        this.setState({ disableButton: true })

        const json = {
            datepgcp,
            datepgce,
            datedem,
            payment_method: echeance.payment_method,
            dates,
            ndossier: dossierValue,
            amendment
        };

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(json)
        };

        await fetch("/api/contrat/updateEch", requestOptions)
            .catch((err) => {
                console.log(err);
            });

        await this.props.dispatch(fetchEcheances());
        await this.props.dispatch(changeAmendmentValue(amendment));
        await this.props.dispatch(fetchAllFac());
        await this.setState({ disableButton: false })
    }

    async handleDeleteAmendment() {
        try {
            this.handleCloseDeleteAmendment();
            await this.setState({ disableButton: true });

            const { amendment, dossierValue: ndossier } = this.props;

            if (!amendment || !ndossier) {
                throw new Error("Données manquantes pour la suppression");
            }

            const requestOptions = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    amendment,
                    ndossier
                })
            };

            const response = await fetch("/api/contrat/deleteEch", requestOptions);

            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();

            if (!data.success) {
                throw new Error(data.message);
            }

            await this.props.dispatch(fetchEcheances());
            await this.props.dispatch(changeAmendmentValue(1));
            await this.props.dispatch(fetchAllFac());

        } catch (error) {
            console.error("Erreur lors de la suppression:", error);
        } finally {
            await this.setState({ disableButton: false });
        }
    }

    closeDoc() {
        this.setState({ isFileOpen: false })
    }

    async handleGenClick() {
        this.handleCloseGen()
        this.setState({ file: null, disableButton: true })
        const { amendment, dossierValue, echeance } = this.props
        const { datedem, datepgcp, datepgce, dates, adresse, ville, cp, comp } = this.state

        let json = {};
        json.dossier = this.getDossier();
        json.dossier.operation_maj = json.dossier.operation.toUpperCase();
        json.devis_con = this.getDevisCon();
        json.devis_rea = this.getDevisRea();
        json.nprop = json.devis_con.nprop.toString().length === 1 ? "0" + json.devis_con.nprop : json.devis_con.nprop;
        json.adresse_mo = adresse;
        json.ville_mo = ville.toUpperCase();
        json.cp_mo = cp;
        json.sci_sccv = comp.toUpperCase();

        const devisConTotalht = this.getDevisCon().totalht;
        const devisReaTotalht = this.getDevisRea().totalht;
        json.totalht_con = devisConTotalht.toFixed(2);
        json.totalttc_con = (devisConTotalht * 1.2).toFixed(2);
        json.totalht_rea = devisReaTotalht.toFixed(2);
        json.totalttc_rea = (devisReaTotalht * 1.2).toFixed(2);
        json.totalht = (Number(devisConTotalht) + Number(devisReaTotalht)).toFixed(2);
        json.totalttc = (Number(devisConTotalht * 1.2) + Number(devisReaTotalht * 1.2)).toFixed(2);

        if (echeance.payment_method == 26) {
            json.totalht_con26 = (devisConTotalht * 0.26).toFixed(2);
            json.totalttc_con26 = (devisConTotalht * 0.26 * 1.2).toFixed(2);
            json.totalht_con74 = (devisConTotalht * 0.74).toFixed(2);
            json.totalttc_con74 = (devisConTotalht * 0.74 * 1.2).toFixed(2);
        } else {
            json.slice34 = ((devisConTotalht + devisReaTotalht) * 0.34).toFixed(2);
        }

        const duree = json.devis_rea.duree;
        json.echnum = this.getEchNum(echeance.payment_method, duree);
        json.echprice_ht = this.getEchPrice(echeance.payment_method, duree).toFixed(2);
        json.echprice_ttc = (this.getEchPrice(echeance.payment_method, duree) * 1.2).toFixed(2);
        json.ndossier = dossierValue
        json.amendment = Number(amendment) < 10 ? "0" + amendment : amendment
        json.offer = json.nprop + " – " + dossierValue.toString().split('').join('-')
        json.datedem = datedem
        json.datepgcp = datepgcp
        json.datepgce = datepgce
        json.dates = dates
        json.payment_method = echeance.payment_method

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(json)
        };
        this.setState({ isFileOpen: true })

        let blob = await fetch("/api/contrat/genEchPDF", requestOptions)
            .then(res => res.blob())
            .catch(err => {
                console.log(err)
            })
        if (blob) {
            this.setState({ file: blob, disableButton: false })
            this.handleUpdateClick()
        }
        await this.setState({ disableButton: false });
    }

    async handleShowClick() {
        this.setState({ file: null, disableButton: true });
        let response = await this.getFile('pdf');

        if (response.blob.size > 100) {
            this.setState({ file: response.blob, isFileOpen: true, disableButton: false });

            const fileName = response.fileName;
            this.props.dispatch(changePdfFileName(fileName))
        } else {
            this.setState({ disableButton: false })
        }
    }

    async handleGetPdfFile() {
        this.setState({ file: null });
        let response = await this.getFile('pdf');

        if (response && response.blob && response.blob.size > 100) {
            this.setState({ file: response.blob });

            const fileName = response.fileName;
            this.props.dispatch(changePdfFileName(fileName));
        }
    }

    async handleGetWordFile() {
        this.setState({ file: null });
        let response = await this.getFile('word');

        if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            this.setState({ file: response.blob });

            const fileName = response.fileName;
            this.props.dispatch(changePdfFileName(fileName))
        }
    }

    async getFile(type) {
        const { dossierValue, amendment } = this.props;
        try {
            const requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({ ndossier: dossierValue, amendment: amendment < 10 ? "0" + amendment : amendment, type })
            };

            const res = await fetch("/api/contrat/showEchPDF", requestOptions);
            const blob = await res.blob();
            const fileName = res.headers.get('X-FileName');

            return { blob, fileName };
        } catch (err) {
            console.log(err);
            return null;
        }
    }



    handleClickOpenCreate = () => {
        this.setState({ openDialogCreate: true });
    };

    handleCloseCreate = () => {
        this.setState({ openDialogCreate: false });
    };

    handleClickOpenDeleteAmendment = () => {
        this.setState({ openDialogDeleteAmendment: true });
    };

    handleCloseDeleteAmendment = () => {
        this.setState({ openDialogDeleteAmendment: false });
    };

    handleClickOpenGen = () => {
        this.setState({ openDialogGen: true });
    };

    handleCloseGen = () => {
        this.setState({ openDialogGen: false });
    };

    handleChangeTitleName() {
        this.props.dispatch(changeTitleName("Avenant Echéances"))
    }
    render() {
        const { echeance, dossierValue, amendment } = this.props
        return (
            <div className="contratech">
                <Header />
                <DocMenu
                    file={this.state.file}
                    getSourceFile={this.handleGetWordFile.bind(this)}
                    getPDF={this.handleGetPdfFile.bind(this)}
                    handleChangeTitleName={this.handleChangeTitleName.bind(this)}
                />
                <NumDoss type="contrat" />
                <h1 className="title">
                    Avenant Echéances
                </h1>
                <div className="data">
                    <div className="dates" style={{ width: "500px", margin: "60px auto 10px auto" }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead className="table-head">
                                    <TableRow className="head-row">
                                        <TableCell className="MuiTableCell-head" colSpan={2}>
                                            Dates
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Démarrage du chantier</TableCell>
                                        <TableCell>
                                            <input
                                                type="date"
                                                name="datedem"
                                                value={this.state.datedem}
                                                onChange={(e) => this.handleChange(e)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><span>Paiement 1<sup>ère</sup> tranche (26% ou 34%)</span></TableCell>
                                        <TableCell>
                                            <input
                                                type="date"
                                                name="datepgcp"
                                                value={this.state.datepgcp}
                                                onChange={(e) => this.handleChange(e)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Envoi du PGC</TableCell>
                                        <TableCell>
                                            <input
                                                type="date"
                                                name="datepgce"
                                                value={this.state.datepgce}
                                                onChange={(e) => this.handleChange(e)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {Object.keys(echeance).length > 0 && <p className="delai">Délai de <span>{echeance?.payment_method == 26 ? 60 : 30} jours</span> entre les échéances</p>}
                    </div>
                    <ContratEchTable
                        dates={this.state.dates}
                        disableButton={this.state.disableButton}
                        amendment={amendment}
                        handleChange={this.handleChange}
                        handleValidClick={this.handleClickOpenCreate}
                        handleDellClick={this.handleClickOpenDeleteAmendment}
                        handleUpdateClick={this.handleUpdateClick}
                        handleGenClick={this.handleClickOpenGen}
                        handleShowClick={this.handleShowClick.bind(this)}
                    />
                </div>
                <YesNoDialog
                    isOpen={this.state.openDialogCreate}
                    title={'CRÉATION AVENANT'}
                    question={`Voulez-vous créer un nouvel avenant d'échéances pour le dossier «${dossierValue !== undefined ? dossierValue : ""}» ?`}
                    handleYesClick={this.handleValidClick}
                    handleClose={this.handleCloseCreate}
                />
                <YesNoDialog
                    isOpen={this.state.openDialogDeleteAmendment}
                    title={'SUPPRESSION AVENANT'}
                    question={`Voulez-vous supprimer l'avenant «N°${amendment !== undefined ? amendment : ""}» du dossier «${dossierValue !== undefined ? dossierValue : ""}» ?`}
                    handleYesClick={this.handleDeleteAmendment}
                    handleClose={this.handleCloseDeleteAmendment}
                />
                <YesNoDialog
                    isOpen={this.state.openDialogGen}
                    title={'GÉNÉRER AVENANT'}
                    question={`Etes-vous sûr de vouloir générer l'avenant «N°${amendment !== undefined ? amendment : ""}» du dossier «${dossierValue !== undefined ? dossierValue : ""}» ?`}
                    handleYesClick={this.handleGenClick}
                    handleClose={this.handleCloseGen}
                />
                <GenPdf isOpen={this.state.isFileOpen} file={this.state.file} closeDoc={this.closeDoc.bind(this)} scale={this.state.file !== null ? 1 : 0.5} />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    deviscon: state.devis.deviscon,
    devisrea: state.devis.devisrea,
    dossierValue: state.devis.dossierValue,
    dossier: state.dossier.items,
    contact: state.contact.items,
    echeances: state.echeances.items,
    echeance: state.echeances.item,
    amendment: state.echeances.amendment,
});
export default connect(mapStateToProps)(ContratEch)