export const checkboxes = [
  "Éclairage intérieur / extérieur",
  "Gaines techniques",
  "Habilitations électrique",
  "Blocs autonomes d’éclairage de sécurité (B.A.E.S) ou (B.A.E.H)",
  "Travail en hauteur",
  "Travaux de peinture",
  "Occultations",
  "Vérification périodique des extincteurs",
  "VMC + conduite gaz",
  "Terrasse inaccessible",
  "Travaux par point chaud",
  "Portes garage principale + parking",
  "Ravalement des façades + ITE",
  "Couverture / charpente / étanchéité",
  "Chauffage",
  "Pompes de relevage parking",
  "Ascenseur",
  "Colonne sèche",
  "Désenfumage",
  "VRD + espace verts",
  "Détecteur de fumée",
  "Vérification périodique de l'extracteur d'air des parkings",
  "Vérification périodique de l'extracteur d'air"
];

export const checkboxesContentsConst = [
  {
    description: "(Voir plans architecte et DOE)",
    localization: "- Dans les parties communes\n(Voir plans architecte et DOE)",
    natureOfInterventions: "- Changements ou maintenance des systèmes d’éclairage\n- Dépoussiérage des lampes et des luminaires extérieurs et intérieurs",
    access: "- Par plate-forme individuelle roulante (P.I.R), l’usage d’échelles et d’escabeaux étant prohibé",
    risks: "- Chute\n- Chute des luminaires\n- Choc électrique\nRisque de heurt avec des personnes",
    securityMeasures: "- Balisage de la zone d’intervention\n- Coupure de circuit électrique dans l’armoire divisionnaire\n- Seul le personnel habilité est autorisé à intervenir\n- Tout le matériel électrique utilisé par les entreprises devra être conforme à la réglementation en vigueur\n- Toute intervention sur les réseaux existant nécessite une procédure écrite et l’apposition d’étiquette de consignation. La validation sera effectuée par le service de sécurité"
  },
  {
    description: "- Gaines palières : courant fort, courant faible, informatique, EDF, services généraux électriques",
    localization: "- Sur les paliers",
    natureOfInterventions: "- Entretien\n- Maintenance\n- Dépannage",
    access: "- De plain-pied",
    risks: "- Électrocution",
    securityMeasures: "- Personnes habilitées à intervenir sur les installations électriques\n- Toute intervention sur les réseaux existant nécessite une procédure écrite et l’apposition d’étiquette de consignation. La validation sera effectuée par le service technique"
  },
  {
    description: "",
    localization: "",
    natureOfInterventions: "",
    access: "",
    risks: "",
    securityMeasures: ""
  },
  {
    description: "",
    localization: "- Circulation\n- Parkings\n- Escaliers\n- Paliers",
    natureOfInterventions: "- Nettoyage\n- Remplacement des lampes\n- Remplacement des batteries ",
    access: "- Par Plates-formes Individuelles Roulantes (P.I.R)",
    risks: "- Chute\n- Électrocution\n- Chute des luminaires\n- Risque de heurt avec des personnes",
    securityMeasures: "- Balisage de la zone d’intervention\n- Coupure du circuit électrique dans l’armoire divisionnaire\n- Personnel habilité\n- Tout le matériel électrique utilisé par les entreprises devra être conforme à la réglementation en vigueur sur les chantiers\n- Toute intervention sur les réseaux existant nécessite une procédure écrite et l’apposition d’étiquette de consignation\n- La validation sera effectuée par les services sécurité de l'immeuble"
  },
  {
    description: "",
    localization: "",
    natureOfInterventions: "",
    access: "",
    risks: "",
    securityMeasures: ""
  },
  {
    description: "- Travaux de peinture sur supports divers (murs, boiseries, canalisations…)\n- Tous les produits à utiliser devront être adaptés aux conditions rencontrées et en fonction de l'exposition des surfaces extérieures et intérieures, de l'exposition en atmosphère agressive, etc.",
    localization: "- Intérieur\n- Extérieur",
    natureOfInterventions: "- Entretien",
    access: "- Par Plate-forme Individuelle Roulante (P.I.R.) l’usage d’échelles et d'escabeaux étant prohibé\n- Échafaudage de pied",
    risks: "- Chutes\n- Utilisation de produits avec du solvant\n- Chute d’objet\n- Heurt avec les personnes",
    securityMeasures: "- Balisage de la zone d’intervention\n- Les modalités d’intervention devront permettre le respect des normes d’évacuation d’urgence\n- Ventilation des locaux\n- Isolation de la zone concernée\n- Préférer les peintures professionnelles en solution aqueuse\n- Demander préalablement au fournisseur la Fiche de Données de Sécurité (F.D.S.) pour s’informer précisément des risques pour la santé"
  },
  {
    description: "- Menuiserie extérieure",
    localization: "- Sur l’ensemble du bâtiment",
    natureOfInterventions: "- Nettoyage\n- Remplacements des vitrages",
    access: "- Accès par nacelle, échafaudages\n- Accès depuis les balcons\n- Accès par la rue pour occultations du RDC",
    risks: "- Coupure\n- Chute de plain-pied\n- Chute d’objet des étages",
    securityMeasures: "- Utiliser des gants de manutention pour manipuler les verres\n- Utilisation d’échafaudage roulant\n- Balisage de la zone d’intervention"
  },
  {
    description: "",
    localization: "",
    natureOfInterventions: "",
    access: "",
    risks: "",
    securityMeasures: ""
  },
  {
    description: "",
    localization: "",
    natureOfInterventions: "- Accès aux réseaux notamment Ventilation Mécanique Contrôlés (contrôles périodiques)",
    access: "- Echafaudages\n- Skydome + Échelle",
    risks: "- Approvisionnement\n- Chute d’objet",
    securityMeasures: "- Port des EPI obligatoire\n- Seul les personnes habilitées à intervenir sur installations de ventilation électrique sont autorisé à intervenir"
  },
  {
    description: "- Toiture terrasse végétalisé",
    localization: "- Terrasse inaccessible avec acrotère normalisé de 1,10m et de garde-corps",
    natureOfInterventions: "- Remplacement de matériel\n- Approvisionnement de matériel\n- Si besoin d’une installation électrique :\n• Prévoir Pose prise de courant 10/16 A + T\n• Protégée par un disjoncteur différentiel\n• Haute sensibilité 30 mA",
    access: "- Echafaudage extérieur à tour\n- Skydome + Échelle",
    risks: "- Approvisionnement\n- Chute d’objet",
    securityMeasures: "- Port des EPI obligatoire\n- Seul les personnes habilitées à intervenir sur installations de ventilation électrique sont autorisé à intervenir\n- Port de harnais de sécurité"
  },
  {
    description: "",
    localization: "",
    natureOfInterventions: "",
    access: "",
    risks: "",
    securityMeasures: ""
  },
  {
    description: "- PORTE MECANIQUE\nLe réseau et le matériel installés dans ce bâtiment sont repérés et décrits dans le dossier des ouvrages exécutés (DOE)",
    localization: "- Entrée et sortie du parking sous-terrain",
    natureOfInterventions: "- Remplacement\n- Entretien moteur\n- Maintenance",
    access: "- Depsis la voirie",
    risks: "- Chute de plain-pied\n- Heurt avec des personnes\n- Heurt avec des véhicules\n- Électrocution",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées à intervenir sur les mécanismes de la porte sont autorisé à intervenir\n- Neutralisation des zones d’accès véhicules (entrant / sortant)\n- Balisage de la zone de travaux\n- Coupure électrique des mécanismes à proximité"
  },
  {
    description: "- Ravalement des façades\n(Voir plan d’architecte pour plus de précision sur les façades)",
    localization: "- Façades Mitoyenne\n- Façade côté rue\n- Façade côté jardin",
    natureOfInterventions: "- Ravalement",
    access: "- Échafaudage encorbellement sur mur mitoyen\n- Échafaudage de pieds ou nacelle télescopique",
    risks: "- Chute de hauteur\n- Chute d’objet",
    securityMeasures: "- Échafaudage à montage en sécurité\n- Filet de protection toute hauteur\n- Tunnel de protection au droit des accès aux bâtiment\n- Balisage de la zone d’évolution de la nacelle\n- Calepinage parfais demandé par l’Inspection du travail"
  },
  {
    description: "- Étanchéité multicouche, sur support béton, composée de :",
    localization: "- Toiture inaccessible",
    natureOfInterventions: "",
    access: "- Skydome + échelles\n- Echafaudage à tour",
    risks: "- Approvisionnement\n- Chute d’objet\n- Chute de hauteur\n- Chute de plain-pied",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées à travailler sur la couverture / charpente / étanchéité peuvent intervenir\n- Port de harnais de sécurité"
  },
  {
    description: "- Réseaux de chauffage (INDIVIDUEL)",
    localization: "",
    natureOfInterventions: "- Accès aux réseaux (contrôles périodiques)\n- Fuites",
    access: "",
    risks: "- Chute de plain-pied",
    securityMeasures: ""
  },
  {
    description: "- Pompe située dans le parking sous-sol",
    localization: "Parking",
    natureOfInterventions: "- Accès aux réseaux (contrôles périodiques)\n- Fuites",
    access: "- Les accès aux locaux techniques sont prévus pour entrer et sortir par des moyens adaptés (porte sécurisé)",
    risks: "- Chute de plain-pied",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir sur les installations"
  },
  {
    description: "- Ascenseur installé dans les parties communes",
    localization: "- Sur les paliers du RDC, R+1, R+2\n- Voir plans d’architecte",
    natureOfInterventions: "- Maintenance\n- Entretien périodique",
    access: "",
    risks: "- Chute de plain-pied\n- Chute de hauteur",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir sur les installations"
  },
  {
    description: "- Colonne sèche mis à disposition des pompiers",
    localization: "- Non indiqué sur les plans d’architecte",
    natureOfInterventions: "- Entretien",
    access: "- De plain-pied ",
    risks: "- Chute de plain-pied",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir sur les installations"
  },
  {
    description: "- Désenfumage mécanique et à gaz installé dans les parties communes",
    localization: "- Sur les paliers du RDC et R+2\n- Voir plans d’architecte",
    natureOfInterventions: "- Maintenance\n- Entretien périodique",
    access: "",
    risks: "",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir sur les installations"
  },
  {
    description: "- Voir Plans",
    localization: "- Voir plans d’architecte",
    natureOfInterventions: "- Maintenance\n- Entretien périodique",
    access: "",
    risks: "",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir dans les parties communes"
  },
  {
    description: "- Voir Plans",
    localization: "- Voir plans d’architecte",
    natureOfInterventions: "- Maintenance\n- Entretien périodique",
    access: "- Voir Plans",
    risks: "",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir sur les installations"
  },
  {
    description: "- Voir Plans",
    localization: "- Voir plans d’architecte",
    natureOfInterventions: "- Maintenance\n- Entretien périodique",
    access: "- Voir Plans",
    risks: "",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir sur les installations"
  },
  {
    description: "- Voir Plans",
    localization: "- Voir plans d’architecte",
    natureOfInterventions: "- Maintenance\n- Entretien périodique",
    access: "- Voir Plans",
    risks: "",
    securityMeasures: "- Port des EPI obligatoire\n- Seuls les personnes habilitées sont autorisées à intervenir sur les installations"
  }
]