import React from 'react';
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import echafaudage from './img/echafaudage.jpg';

const SheetOther = ({ index, sheet, sheetNumber, handleChange, handleFileChange, handleDeleteImage }) => {
  return (
    <div className="sheet-other">
      <table>
        <thead>
          <tr>
            <th colSpan="2"><strong>Fiche n°{index + 1} {sheet.title}</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Description</td>
            <td>Localisation</td>
          </tr>
          <tr>
            <td><textarea value={sheet.description} onChange={handleChange('description')} /></td>
            <td><textarea value={sheet.localization} onChange={handleChange('localization')} /></td>
          </tr>
          <tr>
            <td>Nature des interventions</td>
            <td>Accès</td>
          </tr>
          <tr>
            <td><textarea value={sheet.natureOfInterventions} onChange={handleChange('natureOfInterventions')} /></td>
            <td><textarea value={sheet.access} onChange={handleChange('access')} /></td>
          </tr>
          <tr>
            <td>Risques</td>
            <td>Mesures de sécurité</td>
          </tr>
          <tr>
            <td><textarea value={sheet.risks} onChange={handleChange('risks')} /></td>
            <td><textarea value={sheet.securityMeasures} onChange={handleChange('securityMeasures')} /></td>
          </tr>
          <tr>
            <td colSpan="2">Reportage photographique</td>
          </tr>
          <tr>
            {sheetNumber !== 13 ? <td>
              {sheet.img1 ? (
                <div className="img-item">
                  <IconButton
                    className="item-delete"
                    style={{
                      color: "white",
                      position: "absolute",
                      top: 0,
                      right: 0
                    }}
                    onClick={() => handleDeleteImage('img1')}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                  <img
                    src={URL.createObjectURL(sheet.img1)}
                    alt="Figure 1"
                    style={{ height: "300px", width: "98%" }}
                  />
                  <p>Figure 1</p>
                </div>
              ) : (
                <input type="file" onChange={handleFileChange('img1')} />
              )}
            </td> :
              <td style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className="img-item">
                  <img src={echafaudage} alt="echafaudage" style={{ height: "300px" }} />
                </div>
                <span style={{ textAlign: "left", marginLeft: "3px", fontSize: "15px" }}>
                  <u>Principe d’installation d’échafaudage :</u><br />
                  L'échelle, le contreventement horizontal et la méthode d'assujettissement des planches ont été omis à des fins de clarté.<br /><br />
                  Appliquez les bonnes pratiques suivantes :<br />
                  • N'échappez pas et ne malmenez pas les fixations latérales/amarres lors du montage et du démontage de l'échafaudage. Vous pourriez plier ou endommager les crochets.<br />
                  • Utilisez des planches munies de deux tasseaux à une extrémité pour vous assurer que la fixation soit coincée entre les deux et qu'ainsi la planche ne puisse pas glisser.<br />
                  • Inspectez chaque fixation au moment de l'installation pour vous assurer de n'installer aucune fixation défectueuse.
                </span>
              </td>}
            <td>
              {sheetNumber !== 13 ?
                sheet.img2 ? (
                  <div className="img-item">
                    <IconButton
                      className="item-delete"
                      style={{
                        color: "white",
                        position: "absolute",
                        top: 0,
                        right: 0
                      }}
                      onClick={() => handleDeleteImage('img2')}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                    <img
                      src={URL.createObjectURL(sheet.img2)}
                      alt="Figure 2"
                      style={{ height: "300px", width: "98%" }}
                    />
                    <p>Figure 2</p>
                  </div>
                ) : (
                  <input type="file" onChange={handleFileChange('img2')} />
                ) : sheet.img1 ? (
                  <div className="img-item">
                    <IconButton
                      className="item-delete"
                      style={{
                        color: "white",
                        position: "absolute",
                        top: 0,
                        right: 0
                      }}
                      onClick={() => handleDeleteImage('img1')}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                    <img
                      src={URL.createObjectURL(sheet.img1)}
                      alt="Figure 1"
                      style={{ height: "300px", width: "98%" }}
                    />
                    <p>Figure 1</p>
                  </div>
                ) : (
                  <input type="file" onChange={handleFileChange('img1')} />
                )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SheetOther;